import React, { useEffect } from 'react'
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import FormContainer from '../components/FormContainer'
// import { saveShippingAddress } from '../store/actions/cartActions'
import Loader from '../../components/Loader'
import CheckoutSteps from '../../components/CheckoutSteps'
import Message from '../../components/Message'
import { createOrder } from '../../store/actions/orderActions'
import { ORDER_CREATE_RESET } from '../../store/constants/orderConstants'


function PlaceOrderScreen() {

  const orderCreate = useSelector(state => state.orderCreate)

  const { order, error, success, loading } = orderCreate

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const cart = useSelector(state => state.cart)
  const shippingInfo = useSelector(state => state.shippingInfo)
  const paymentMethodInfo = useSelector(state => state.paymentMethodInfo)
  const { shippingAddress } = shippingInfo
  const { paymentMethod } = paymentMethodInfo

  // acc acumulator
  cart.itemsPrice = cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2)
  cart.shippingPrice = (cart.itemsPrice > 10 ? 0 : 100).toFixed(2)
  //cart.taxPrice = Number((0.0082) * cart.itemsPrice).toFixed(2)
  cart.taxPrice = Number((0.0082) * 0).toFixed(2)
  cart.totalPrice = (Number(cart.itemsPrice)+  Number(cart.shippingPrice) + Number(cart.taxPrice)).toFixed(2)

  useEffect(() => {
    if(!paymentMethod) {
      navigate("/payment")
    }

    if(success){
      navigate(`/order/${order.id}`)
      dispatch({
        type: ORDER_CREATE_RESET,
      })
    }
  }, [success, navigate, paymentMethod, dispatch, order]);

  const placeOrder = () => {
    dispatch(createOrder({
      order_items: cart.cartItems,
      shipping_address: shippingAddress,
      payment_method: paymentMethod,
      items_price: parseFloat(cart.itemsPrice),
      shipping_price: parseFloat(cart.shippingPrice),
      tax_price: parseFloat(cart.taxPrice),
      total_price: parseFloat(cart.totalPrice),
    }))
  }
  return (
    <div>
      <CheckoutSteps step1 step2 step3 step4  ></CheckoutSteps>
      {loading ? <Loader />
       : error ? <Message variant='danger'>{error}</Message>
       :
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Shipping</h2>

              <p>
                <strong>Shipping: </strong>
                { shippingAddress.address }, { shippingAddress.city }
                {' '}
                { shippingAddress.postalCode }
                {' '}
                { shippingAddress.country }
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>

              <p>
                <strong>Method: </strong>
                { paymentMethod }
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {cart.cartItems.length === 0 ? <Message variant="warning">
                Your cart is empty
              </Message> : (
                <ListGroup variant='flush'>
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image src={item.image} alt={item.name} fluid rounded/>
                        </Col>

                        <Col>
                          <Link to={ `/product/${item.product}` }>{item.name}</Link>
                        </Col>

                        <Col md={4}>
                          {item.qty}x{item.price} = KES{(item.qty * item.price).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4} className='py-2'>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Items:</Col>
                  <Col>KES{cart.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Shipping:</Col>
                  <Col>KES{cart.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Tax:</Col>
                  <Col>KES{cart.taxPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Total:</Col>
                  <Col>KES{cart.totalPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>

              </ListGroup.Item>
                  {error && <Message variant='danger'>{ error }</Message>}
              <ListGroup.Item>

              <div className="d-grid gap-2">
                <Button
                  type="button"
                  className='btn-block'
                  disabled={cart.cartItems === 0}
                  onClick={placeOrder}>
                  Place Order
                </Button>
              </div>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>

      }
      
    </div>
  )
}

export default PlaceOrderScreen
