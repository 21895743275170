import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
    documentDeleteReducer,
    documentDetailsReducer,
    documentListReducer,
    documentCreateReducer,
    documentReviewCreateReducer,
    documentTopRatedReducer,
    documentUpdateReducer,

} from './reducers/documentReducers'
import { cartReducer, paymentMethodReducer, shippingReducer } from './reducers/cartReducers'
import { 
    orderCreateReducer, 
    orderListReducer, 
    orderReducer, 
    orderUpdateReducer, 
    userOrderListReducer,
} from './reducers/orderReducers'
import {
    userDeleteReducer,
    userDetailsReducer,
    userListReducer,
    userLoginReducer,
    userRegisterReducer,
    userUpdateProfileReducer,
    userUpdateReducer,
} from './reducers/userReducers'
import { 
    categoryListReducer, 
    categoryDeleteReducer, 
    categoryCreateReducer, 
    categoryDetailsReducer,
} from './reducers/categoryReducers'
import { paymentListReducer } from './reducers/paymentReducer'

import {
    mailDeleteReducer,
    mailDetailsReducer,
    mailListReducer,
    mailCreateReducer,
    mailRecipientsListReducer,
} from './reducers/mailReducers'

const reducer = combineReducers({
    cart: cartReducer,

    categoryList: categoryListReducer,
    categoryCreate: categoryCreateReducer,
    categoryDelete: categoryDeleteReducer,
    categoryDetails: categoryDetailsReducer,

    documentCreate: documentCreateReducer,
    documentList: documentListReducer,
    documentDetails: documentDetailsReducer,
    documentDelete: documentDeleteReducer,
    documentReviewCreate: documentReviewCreateReducer,
    documentTopRated: documentTopRatedReducer,
    documentUpdate: documentUpdateReducer,

    mailCreate: mailCreateReducer,
    mailList: mailListReducer,
    mailDetails: mailDetailsReducer,
    mailDelete: mailDeleteReducer,
    mailRecipientList: mailRecipientsListReducer,

    orderCreate: orderCreateReducer,
    orderDetails: orderReducer,
    orderList: orderListReducer,
    orderUpdate: orderUpdateReducer,

    paymentMethodInfo: paymentMethodReducer,
    paymentList: paymentListReducer,
    
    shippingInfo: shippingReducer,

    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userOrderList: userOrderListReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null

const cartItemsFromStorage = localStorage.getItem('cartItems') ?
    JSON.parse(localStorage.getItem('cartItems')) : []

const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ?
    JSON.parse(localStorage.getItem('shippingAddress')) : null

const paymentMethodFromStorage = localStorage.getItem('paymentMethod') ?
    JSON.parse(localStorage.getItem('paymentMethod')) : null

const initialState = {
    cart: { cartItems: cartItemsFromStorage },
    shippingInfo: { shippingAddress: shippingAddressFromStorage },
    paymentMethodInfo: { paymentMethod: paymentMethodFromStorage },
    userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware)),
)

export default store