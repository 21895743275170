import React, { useState, useEffect } from 'react'
import { Form, Button, Card } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../components/FormContainer'
// import { USER_UPDATE_SUCCESS } from '../../store/constants/userConstants'
import { getCategoryDetails } from '../../store/actions/categoryActions'

function EditCategoryScreen() {

    // url parameters
    const { id } = useParams()

    //initializing the dispatch used to dispatch actions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // form values
    const [name, setName] = useState('')
    const [level, setLevel] = useState('primary')

    // getting the state
    const categoryDetails = useSelector(state => state.categoryDetails)
    const { error, loading, category } = categoryDetails

    // const categoryUpdate = useSelector(state => state.categoryUpdate)
    // const { 
    //     error: errorUpdate, 
    //     success: successUpdate, 
    //     loading: loadingUpdate,
    // } = categoryUpdate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {

        // if (successUpdate) {
        //     dispatch({type: CATEGORY_UPDATE_RESET})
        //     navigate("/admin/categories")
        // }

        if (!userInfo && userInfo.is_admin) {
            navigate("/login")
        } else {
            if (!category.name || category.id !== Number(id)) {
                dispatch(getCategoryDetails(id))
            } else {
                setName(category.name)
                setLevel(category.level)
            }
        }

    }, [id, dispatch, category, navigate, userInfo])


    const submitHandler = (e) => {
        e.preventDefault()
        // UPDATE DOCUMENT
        //dispatch(categoryUp)
    }


    return (
        <div>
            <Link to="/admin/categories" className='btn btn-light my-3'> Go Back</Link>
            <FormContainer>
                <Card className='shadow mb-4'>
                    <Card.Header>
                        <Card.Title>
                            Edit Category
                        </Card.Title>
                    </Card.Header>
                    <Card.Body className='p-4'>
                        {/* {loadingUpdate && <Loader />} */}
                        {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                            : (
                                <Form onSubmit={submitHandler}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="level">
                                        <Form.Label>Level</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Level"
                                            value={level}
                                            onChange={(e) => setLevel(e.target.value)}>
                                        </Form.Control>
                                    </Form.Group>

                                    <Button
                                        type="submit"
                                        variant='primary'
                                        className='my-3'>
                                        Update
                                    </Button>
                                </Form>
                            )}
                    </Card.Body>
                </Card>
            </FormContainer>
        </div>
    )
}

export default EditCategoryScreen
