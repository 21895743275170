export const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const parseToken = (token) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonData =  decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonData);
}

// export const BACKEND_URL = "http://localhost:8000";

// export const AUTH_URL = "http://localhost:8083";

export const BACKEND_URL = "https://backend.studyrepokenya.com";

export const AUTH_URL = "https://auth.studyrepokenya.com";

export const MAIL_URL = "https://mail.payhero.co.ke";

export const USERNAME = "Uq5j2YSLKE0Fg04sX0zx";

export const PASSWORD = "uEyjGyFHdGV8s7ZedOj0WG6IBdNNHYGXnLyMBXbs";

export const ACCOUNT_ID = 68;
