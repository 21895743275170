import React from 'react'
import { Nav } from 'react-bootstrap';
import './sidebar.css'

function SideBar() {

    return (
        <div>
            <h2>Filters</h2><hr/>
            <Nav className="flex-column">
                <Nav.Item className='item' id='first'>
                    <Nav.Link href="?grade=13">Pre-Primary 1</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=14">Pre-Primary 2</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=1">Grade One</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=2">Grade Two</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=3">Grade Three</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=4">Grade Four</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=5">Grade Five</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=6">Grade Six</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=7">Grade Seven</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=8">Grade Eight</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=9">Form One</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=10">Form Two</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=11">Form Three</Nav.Link>
                </Nav.Item>
                <Nav.Item className='item'>
                    <Nav.Link href="?grade=12">Form Four</Nav.Link>
                </Nav.Item>
            </Nav>

        </div>
    )
}

export default SideBar;