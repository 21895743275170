import React, { useState, useEffect } from 'react'
import { Form, Button, Card } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../components/FormContainer'
// import { USER_UPDATE_SUCCESS } from '../../store/constants/userConstants'
import { createCategory } from '../../store/actions/categoryActions'
import { DOCUMENT_CATEGORY_CREATE_RESET } from '../../store/constants/categoryConstants'


function CreateCategoryScreen() {

    // url parameters
    // const { id } = useParams()

    //initializing the dispatch used to dispatch actions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // form values
    const [name, setName] = useState('')
    const [level, setLevel] = useState('primary')

    // getting the state
    const categoryCreate = useSelector(state => state.categoryCreate)
    const { error, loading, success } = categoryCreate

    // const categoryList = useSelector(state => state.categoryList)
    // const { error: errorCategory, categories } = categoryList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (!userInfo && !userInfo.is_admin) {
            navigate('/login')
        } else {
            if (success) {
                dispatch({ type: DOCUMENT_CATEGORY_CREATE_RESET })
                navigate('/admin/categories')
            }
        }
    }, [navigate, userInfo, success, dispatch])


    const submitHandler = (e) => {
        e.preventDefault()
        // CREATE DOCUMENT
        dispatch(createCategory({
            name,
            level,
        }))
    }


    return (
        <div>
            <Link to="/admin/categories" className='btn btn-light my-3'> Go Back</Link>
            <FormContainer>
                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Card className='p-4 shadow mb-4'>
                            <Card.Header>
                                <Card.Title>Create Document Category</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={submitHandler}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="name">
                                        <Form.Label>Level</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Level"
                                            value={level}
                                            onChange={(e) => setLevel(e.target.value)}>
                                        </Form.Control>
                                    </Form.Group>

                                    <Button
                                        type="submit"
                                        variant='primary'
                                        className='my-3'
                                        onClick={submitHandler}>
                                        Create
                                    </Button>
                                </Form>
                            </Card.Body>

                        </Card>
                    )}
            </FormContainer>
        </div>
    )
}

export default CreateCategoryScreen
