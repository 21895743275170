import React, { useState, useEffect } from 'react'
import { Form, Button, Card } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../components/FormContainer'
import { USER_UPDATE_SUCCESS } from '../../store/constants/userConstants'
import { getDocumentDetails, listDocuments, updateDocument } from '../../store/actions/documentActions'
import { listCategories } from '../../store/actions/categoryActions'

function EditDocumentScreen() {

    // url parameters
    const { id } = useParams()

    //initializing the dispatch used to dispatch actions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // form values
    const [name, setName] = useState('')
    const [grade, setGrade] = useState(1)
    const [document_level, setLevel] = useState('primary')
    const [document_type_id, setDocumentTypeID] = useState(1)
    const [term, setTerm] = useState('Term 1')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState(0.0)
    const [num_reviews, setNumReviews] = useState(10)
    const [ratings, setRatings] = useState(5)

    // getting the state
    const documentsDetails = useSelector(state => state.documentDetails)
    const { error, loading, document } = documentsDetails

    const categoryList = useSelector(state => state.categoryList)
    const { error: errorCategory, categories } = categoryList

    const documentUpdate = useSelector(state=>state.documentUpdate)
    const { error: documentUpdateError, loading: documentUpdateLoading, success } = documentUpdate

    useEffect(() => {
        // get the list of categories
        dispatch(listCategories())

        if (!document.name || document.id !== Number(id)) {
            dispatch(getDocumentDetails(id))
        } else {
            setName(document.name)
            setGrade(Number(document.grade))
            setLevel(document.document_level)
            setDocumentTypeID(document.document_type_id)
            setTerm(document.term)
            setDescription(document.description)
            setPrice(document.price)
            setNumReviews(document.num_reviews)
            setRatings(document.ratings)
        }

        if (success) {
            navigate(`/admin/documents`)
        }

    }, [id, dispatch, document, navigate, success])


    const submitHandler = (e) => {
        e.preventDefault()

        dispatch(updateDocument({
            "name": name,
            "grade": Number(grade),
            document_level,
            "document_type_id": Number(document_type_id),
            term,
            description,
            "price": Number(price),
            "num_reviews": Number(num_reviews),
            "ratings": Number(ratings),
        }, id))
    }


    return (
        <div>
            <Link to="/admin/documents" className='btn btn-light my-3'> Go Back</Link>
            <FormContainer>
                <Card className='shadow mb-4'>
                    <Card.Header>
                        <Card.Title>
                            Edit Document
                        </Card.Title>
                    </Card.Header>
                    <Card.Body className='p-4'>
                        {documentUpdateLoading && <Loader />}
                        {documentUpdateError && <Message variant='danger'>{documentUpdateError}</Message>}
                        {errorCategory && <Message variant='danger'>{errorCategory}</Message>}
                        {success && <Message variant='success'>Updated document successfully</Message>}
                        {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                            : (
                                <Form onSubmit={submitHandler}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Document Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="grade">
                                            <Form.Label>Grade</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                value={grade}
                                                onChange={(e) => setGrade(e.target.value)}>
                                                <option value={13}>PP1</option>
                                                <option value={14}>PP2</option>
                                                <option value={1}>Grade 1</option>
                                                <option value={2}>Grade 2</option>
                                                <option value={3}>Grade 3</option>
                                                <option value={4}>Grade 4</option>
                                                <option value={5}>Grade 5</option>
                                                <option value={6}>Grade 6</option>
                                                <option value={7}>Grade 7</option>
                                                <option value={8}>Grade 8</option>
                                                <option value={9}>Form 1</option>
                                                <option value={10}>Form 2</option>
                                                <option value={11}>Form 3</option>
                                                <option value={12}>Form 4</option>
                                            </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="document_level">
                                            <Form.Label>Document Level</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                value={document_level}
                                                onChange={(e) => setLevel(e.target.value)}>
                                                <option value="pre-primary">Pre-Primary</option>
                                                <option value="primary">Primary</option>
                                                <option value="junior-secondary">Junior Secondary</option>
                                                <option value="secondary">Secondary</option>
                                            </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="document_type_id">
                                        <Form.Label>Document Type</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={document_type_id}
                                            onChange={(e) => setDocumentTypeID(e.target.value)}
                                        >
                                            {
                                                categories.map((category) => (
                                                    <option key={category.id} value={category.id}>
                                                        {category.name}
                                                    </option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="term">
                                            <Form.Label>Term</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                value={term}
                                                onChange={(e) => setTerm(e.target.value)}>
                                                <option value="Term 1">Term 1</option>
                                                <option value="Term 2">Term 2</option>
                                                <option value="Term 3">Term 3</option>
                                            </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="price">
                                        <Form.Label>Price: (KES) </Form.Label>
                                        <Form.Control
                                            type="number"
                                            required
                                            placeholder="Enter Price"
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)}>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="description">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}>
                                            </Form.Control>
                                        </Form.Group>

                                    <Button
                                        type="submit"
                                        variant='primary'
                                        className='my-3'>
                                        Update
                                    </Button>
                                </Form>
                            )}
                    </Card.Body>
                </Card>
            </FormContainer>
        </div>
    )
}

export default EditDocumentScreen
