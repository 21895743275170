import React, { useState } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../components/FormContainer'
import { savePaymentMethod } from '../../store/actions/cartActions'
import CheckoutSteps from '../../components/CheckoutSteps'

function PaymentScreen() {

    const shippingInfo = useSelector(state => state.shippingInfo)
    const { shippingAddress } = shippingInfo

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [paymentMethod, setPaymentMethod] = useState('M-Pesa')

    if (!shippingAddress.emailAddress) {
        navigate('/shipping')
    }

    const submitHandler = (e) => {
        e.preventDefault()

        dispatch(savePaymentMethod(paymentMethod))

        navigate('/placeorder')
    }

  return (
    <FormContainer>
        <CheckoutSteps step1 step2 />

        <Form onSubmit = {submitHandler}>
            <Form.Group>
                <Form.Label as="legend">Select Method</Form.Label>
                <Col>
                    <Form.Check
                        type="radio"
                        label='Mpesa'
                        id='mpesa'
                        name='paymentMethod'
                        checked
                        onChange={(e) => setPaymentMethod(e.target.value)}>
                    </Form.Check>
                </Col>
            </Form.Group>

            <Button type='submit' variant='primary'>
                Continue
            </Button>

        </Form>
    </FormContainer>
  )
}

export default PaymentScreen
