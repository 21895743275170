import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Card } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../components/FormContainer'
// import { USER_UPDATE_SUCCESS } from '../../store/constants/userConstants'
import { listCategories } from '../../store/actions/categoryActions'
import { createDocument } from '../../store/actions/documentActions'


function CreateDocument() {

    // url parameters
    // const { id } = useParams()

    //initializing the dispatch used to dispatch actions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // form values
    const [file, setFile] = useState('')
    const [grade, setGrade] = useState(13)
    const [document_level, setDocumentLevel] = useState('pre-primary')
    const [document_type_id, setDocumentTypeID] = useState(1)
    const [term, setTerm] = useState('Term 1')
    const [image, setImage] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState(0.0)
    const [subject, setSubject] = useState("")
    const [brand, setBrand] = useState("")
    //const [name, setName] = useState('')

    // getting the state
    const documentCreate = useSelector(state => state.documentCreate)
    const { error, loading, success } = documentCreate
    
    const categoryList = useSelector(state => state.categoryList)
    const { error: errorCategory, categories } = categoryList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {

        // get the list of categories
        dispatch(listCategories())

        if (!userInfo && !userInfo.is_admin) {
            navigate('/login')
        } else {
            if (success) {
                navigate('/admin/documents')
            }
        }
    }, [navigate, userInfo, success, dispatch])


    const submitHandler = (e) => {
        e.preventDefault()
        // CREATE DOCUMENT
        dispatch(createDocument({
            file,
            grade,
            document_level,
            document_type_id,
            term,
            image,
            description,
            price,
            subject,
            brand,
        }))
    }


    return (
        <div>
            <Link to="/admin/documents" className='btn btn-light my-3'> Go Back</Link>
            <FormContainer cols={12}>
                {errorCategory && <Message variant='danger'>{errorCategory}</Message>}
                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Card className='p-4 shadow mb-4'>
                            <Card.Header>
                                <Card.Title>Create Document</Card.Title>
                            </Card.Header>
                            <Card.Body>
                            <Form onSubmit={submitHandler}>
                                <Row>

                                    <Col md={6}>

                                        <h3>Document Details</h3><hr />

                                        <Form.Group controlId="grade">
                                            <Form.Label>Grade</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                value={grade}
                                                onChange={(e) => setGrade(e.target.value)}>
                                                <option value={13}>PP1</option>
                                                <option value={14}>PP2</option>
                                                <option value={1}>Grade 1</option>
                                                <option value={2}>Grade 2</option>
                                                <option value={3}>Grade 3</option>
                                                <option value={4}>Grade 4</option>
                                                <option value={5}>Grade 5</option>
                                                <option value={6}>Grade 6</option>
                                                <option value={7}>Grade 7</option>
                                                <option value={8}>Grade 8</option>
                                                <option value={9}>Form 1</option>
                                                <option value={10}>Form 2</option>
                                                <option value={11}>Form 3</option>
                                                <option value={12}>Form 4</option>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="document_level">
                                            <Form.Label>Document Level</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                value={document_level}
                                                onChange={(e) => setDocumentLevel(e.target.value)}>
                                                <option value="pre-primary">Pre-Primary</option>
                                                <option value="primary">Primary</option>
                                                <option value="junior-secondary">Junior Secondary</option>
                                                <option value="secondary">Secondary</option>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="document_type_id">
                                            <Form.Label>Document Type</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                value={document_type_id}
                                                onChange={(e) => setDocumentTypeID(e.target.value)}
                                            >
                                                <option>
                                                    Select Document Category
                                                </option>
                                                {
                                                    categories.map((category) => (
                                                        <option key={category.id} value={category.id}>
                                                            {category.name}
                                                        </option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="term">
                                            <Form.Label>Term</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                value={term}
                                                onChange={(e) => setTerm(e.target.value)}>
                                                <option value="Term 1">Term 1</option>
                                                <option value="Term 2">Term 2</option>
                                                <option value="Term 3">Term 3</option>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="price">
                                            <Form.Label>Price: (KES) </Form.Label>
                                            <Form.Control
                                                type="number"
                                                required
                                                placeholder="Enter Price"
                                                value={price}
                                                onChange={(e) => setPrice(e.target.value)}>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="brand">
                                            <Form.Label>Brand</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                placeholder="Enter Brand"
                                                value={brand}
                                                onChange={(e) => setBrand(e.target.value)}>
                                            </Form.Control>
                                        </Form.Group>

                                    </Col>

                                    <Col md={6}>
                                        <h3>Extra Details</h3><hr />

                                        <Form.Group controlId="file">
                                            <Form.Label>Select Document</Form.Label>
                                            <Form.Control
                                                type="file"
                                                required
                                                onChange={(e) => setFile(e.target.files[0])}>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="image">
                                            <Form.Label>Select Cover Image</Form.Label>
                                            <Form.Control
                                                type="file"
                                                required
                                                accept=".png,.jpg,.jpeg,.webp"
                                                onChange={(e) => setImage(e.target.files[0])}>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="description">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="subject">
                                            <Form.Label>Subject</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                placeholder="Enter Subject"
                                                value={subject}
                                                onChange={(e) => setSubject(e.target.value)}>
                                            </Form.Control>
                                        </Form.Group>

                                        <Row>
                                            <Button
                                                type="submit"
                                                variant='primary'
                                                className='my-3'
                                                onClick={submitHandler}>
                                                Create
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                            </Card.Body>
                        </Card>
                    )}
            </FormContainer>
        </div>
    )
}

export default CreateDocument
