import React, { useState, useEffect } from 'react'
import { Table, Button, Card } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUser, listUsers } from '../../store/actions/userActions'
import FormContainer from '../../components/FormContainer'


function UserListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // getting state
  const userList = useSelector(state => state.userList)
  const { loading, error, users } = userList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const userDelete = useSelector(state => state.userDelete)
  const { succes: successDelete } = userDelete


  useEffect(() => {
    if (userInfo && userInfo.is_admin) {
      dispatch(listUsers())
    } else {
      navigate('/login')
    }
  }, [dispatch, navigate, successDelete, userInfo])

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteUser(id))
    }
  }
  return (
    <div>
      <Card className="shadow mb-2">
        <Card.Header>
          <Card.Title>
            Users
          </Card.Title>
        </Card.Header>
        <Card.Body className='d-flex flex-column px-3'>
          {loading
            ? (<Loader />)
            : error
              ? (<Message variant='danger'>{error}</Message>)
              : (
                <Table striped responsive bordered hover className='table-sm'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Admin</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {users.map(user => (
                      <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>{user.first_name}</td>
                        <td>{user.last_name}</td>
                        <td>{user.email}</td>
                        <td>{user.is_admin ? (
                          <i className='fas fa-check' style={{ color: "green" }}></i>
                        ) : (
                          <i className='fas fa-check' style={{ color: "red" }}></i>
                        )}</td>
                        <td>
                          <LinkContainer to={`/admin/users/${user.id}/edit`}>
                            <Button className='btn-sm' variant='light'>
                              <i className='fas fa-edit'></i>
                            </Button>


                          </LinkContainer>
                          <Button className='btn-sm' variant='danger' onClick={() => deleteHandler(user.id)}>
                            <i className='fas fa-trash ml-4'></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
        </Card.Body>
      </Card>
    </div>
  )
}

export default UserListScreen
