import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap'
import { useParams, useNavigate } from "react-router-dom";
import Rating from '../../components/Rating'
import Loader from '../../components/Loader';
import Message from '../../components/Message';
//import { getDocumentDetails } from '../../store/actions/documentActions';
import { createDocumentReview, getDocumentDetails } from '../../store/actions/documentActions';
import { DOCUMENT_CREATE_REVIEW_RESET } from '../../store/constants/documentConstants';
import { USER_UPDATE_PROFILE_RESET } from '../../store/constants/userConstants';
import { getUserDetails } from '../../store/actions/userActions';

function DocumentScreen() {

    // quantity
    const [qty, setQty] = useState(1) // use state is used for component level state
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('')
    // get the params
    const { id } = useParams()
    const navigate = useNavigate();
    // initialize the dispatch object
    const dispatch = useDispatch()
    // get the items from store
    const documentsDetails = useSelector(state => state.documentDetails)
    // destructuring items from store
    const { error, loading, document } = documentsDetails

    const userLogin = useSelector(state => state.userLogin)
    // destructuring items from store
    const { userInfo } = userLogin

    // get the items from store
    const userDetails = useSelector(state => state.userDetails)
    // destructuring items from store
    const { user } = userDetails

    const documentReviewCreate = useSelector(state => state.documentReviewCreate)
    // destructuring items from store
    const {
        error: errorDocumentReview,
        loading: loadingDocumentReview,
        success: successDocumentReview,
    } = documentReviewCreate

    useEffect(() => {

        if (user) {
            if (!user.first_name) {
                dispatch({ type: USER_UPDATE_PROFILE_RESET })
                dispatch(getUserDetails(user.user_id))
            }
        }

        if (successDocumentReview) {
            setComment('')
            setRating(0)
            dispatch({ type: DOCUMENT_CREATE_REVIEW_RESET })
        }

        dispatch(getDocumentDetails(id))

    }, [dispatch, id, successDocumentReview, user])

    const addToCartHandler = () => {
        navigate(`/cart/${id}?qty=${qty}`)
    }

    const submitHandler = (e)=> {
        e.preventDefault()

        const payload = {
            "document_id": Number(id),
            "rating": Number(rating),
            "comment": comment,
            "name": `${user.first_name} ${user.last_name}`
        }
        dispatch(createDocumentReview(payload))
    }

    return (
        <div>

            <Link to="/" className='btn btn-light my-3'> Go Back</Link>
            {loading ?
                <Loader />
                : error
                    ? <Message variant='danger'>{error}</Message>
                    : (
                        <div>
                            <Row>
                                <Col md={3}>
                                    <Image src={document.cover_image} alt={document.name} fluid />
                                </Col>
                                <Col md={6}>
                                    <ListGroup variant='flush'>
                                        <ListGroup.Item>
                                            <h3>{document.name}</h3>
                                        </ListGroup.Item>

                                        <ListGroup.Item>
                                            <Rating value={document.ratings} text={`${document.num_reviews} reviews`} color={'#f8e825'} />
                                        </ListGroup.Item>

                                        <ListGroup.Item>
                                            <h3>Ksh. {document.price}</h3>
                                        </ListGroup.Item>

                                        <ListGroup.Item>
                                            Description. {document.description}
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col md={3}>
                                    <Card>
                                        <ListGroup variant='flush'>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col>Price:</Col>
                                                    <Col> <strong>Ksh. {document.price}</strong></Col>
                                                </Row>
                                            </ListGroup.Item>

                                            <ListGroup.Item>
                                                <Row>
                                                    <Col>Status:</Col>
                                                    <Col>
                                                        {document.count_in_stock > 0 ? 'In Stock' : 'Out of Stock'}
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>

                                            {document.count_in_stock > 0 && (
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col>Qty</Col>
                                                        <Col xs='auto' className='my-1'>
                                                            <Form.Control
                                                                as="select"
                                                                value={qty}
                                                                onChange={(e) => setQty(e.target.value)}
                                                            >
                                                                {
                                                                    [...Array(document.count_in_stock).keys()].map((x) => (
                                                                        <option key={x + 1} value={x + 1}>
                                                                            {x + 1}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </Form.Control>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )}

                                            <ListGroup.Item>
                                                <div className="d-grid gap-2">
                                                    <Button
                                                        onClick={addToCartHandler}
                                                        className='btn-block'
                                                        type="button"
                                                        disabled={document.count_in_stock === 0}>
                                                        Add To Cart
                                                    </Button>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="my-4">
                                <Col md={6}>
                                    <h4>Reviews</h4>
                                    {document.review_list.reviews.length === 0 && <Message variant='info'>No Reviews</Message>}
                                    <ListGroup variant='flush'>
                                        {document.review_list.reviews.map((review) => (
                                            <ListGroup.Item key={review.id}>
                                                <strong>{review.name}</strong>
                                                <Rating value={review.rating} color='#f8e825' />
                                                <p>{review.created_at.substring(0, 10)}</p>
                                                <p>{review.comment}</p>
                                            </ListGroup.Item>
                                        ))}

                                        <ListGroup.Item>
                                            <h4>Write a Review</h4>
                                            {loadingDocumentReview && <Loader />}
                                            {successDocumentReview && <Message variant='success'>Review submitted</Message>}
                                            {errorDocumentReview && <Message variant='danger'>{errorDocumentReview}</Message>}

                                            {userInfo ? (
                                                <Form onSubmit={submitHandler}>
                                                    <Form.Group controlId='rating'>
                                                        <Form.Label>Rating</Form.Label>
                                                        <Form.Control
                                                        as='select'
                                                        value={rating}
                                                        onChange={(e)=> setRating(e.target.value)}>

                                                            <option value=''>Select...</option>
                                                            <option value='1'>1 - Poor.</option>
                                                            <option value='2'>2 - Fair.</option>
                                                            <option value='3'>3 - Good.</option>
                                                            <option value='4'>4 - Very Good.</option>
                                                            <option value='5'>5 - Excellent.</option>

                                                        </Form.Control>
                                                    </Form.Group>

                                                    <Form.Group controlId='comment'>
                                                        <Form.Label>Review</Form.Label>
                                                        <Form.Control
                                                        as="textarea"
                                                        rows='5'
                                                        value={comment}
                                                        onChange={(e)=> setComment(e.target.value)}>
                                                        </Form.Control>

                                                        <Button
                                                            disabled={loadingDocumentReview}
                                                            type='submit'
                                                            className='m-2'
                                                        >
                                                            Submit
                                                        </Button>

                                                    </Form.Group>
                                                </Form>
                                            ) : (
                                                <Message variant='info'>
                                                    Please <Link to='/login'>Login</Link> to write a review.
                                                </Message>
                                            )}
                                        </ListGroup.Item>

                                    </ListGroup>
                                </Col>
                            </Row>
                        </div>
                    )
            }

        </div>
    )
}

export default DocumentScreen
