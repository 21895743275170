export const MAIL_LIST_REQUEST = 'MAIL_LIST_REQUEST'
export const MAIL_LIST_SUCCESS = 'MAIL_LIST_SUCCESS'
export const MAIL_LIST_FAIL = 'MAIL_LIST_FAIL'

export const MAIL_CREATE_REQUEST = 'MAIL_CREATE_REQUEST'
export const MAIL_CREATE_SUCCESS = 'MAIL_CREATE_SUCCESS'
export const MAIL_CREATE_FAIL = 'MAIL_CREATE_FAIL'
export const MAIL_CREATE_RESET = 'MAIL_CREATE_RESET'

export const EMAIL_DETAILS_REQUEST = "EMAIL_DETAILS_REQUEST"
export const EMAIL_DETAILS_SUCCESS = "EMAIL_DETAILS_SUCCESS"
export const EMAIL_DETAILS_FAIL = "EMAIL_DETAILS_FAIL"

export const EMAIL_DELETE_REQUEST = "EMAIL_DELETE_REQUEST"
export const EMAIL_DELETE_SUCCESS = "EMAIL_DELETE_SUCCESS"
export const EMAIL_DELETE_FAIL = "EMAIL_DELETE_FAIL"

export const MAIL_RECIPIENT_TOP_REQUEST = 'MAIL_RECIPIENT_TOP_REQUEST'
export const MAIL_RECIPIENT_TOP_SUCCESS = 'MAIL_RECIPIENT_TOP_SUCCESS'
export const MAIL_RECIPIENT_TOP_FAIL = 'MAIL_RECIPIENT_TOP_FAIL'
