import React, { useCallback, useEffect } from 'react'
import { Table, Button, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Paginate from '../../components/Paginate'
import PillBadge from '../../components/PillBadge'
import { listPayments } from '../../store/actions/paymentAction'

function PaymentListScreen() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [queryParameters, setSearchParams] = useSearchParams()

    const paymentList = useSelector(state => state.paymentList)
    const { loading, error, payments, page, pages } = paymentList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (userInfo && userInfo.is_admin) {
            dispatch(listPayments(queryParameters))
        } else {
            navigate("/login")
        }
    }, [userInfo, dispatch, navigate, queryParameters])

    const handleChangePage = useCallback((page) => {
        let params = Object.fromEntries(...queryParameters)
        if (params.hasOwnProperty("page")) {
            params.page = page
        } else {
            params["page"] = page
        }

        setSearchParams(createSearchParams(params))
    }, [queryParameters, setSearchParams]) 

    return (
        <div>

            <Card className='shadow mb-2'>
                <Card.Header>
                    <Card.Title>
                        Payments
                    </Card.Title>
                </Card.Header>
                <Card.Body className='p-4'>
                    {loading
                        ? (<Loader />)
                        : error
                            ? (<Message variant='danger'>{error}</Message>)
                            : (
                                <div>
                                    <Table striped responsive bordered hover className='table-sm'>
                                        <thead>
                                            <tr>
                                                <th>Transaction Code</th>
                                                <th>Description</th>
                                                <th>Customer Name</th>
                                                <th>Price</th>
                                                <th>Phone Number</th>
                                                <th>Order ID</th>
                                                <th>Request Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {payments.map(payment => (
                                                <tr key={payment.id}>
                                                    <td>{payment.transaction_code}</td>
                                                    <td>{payment.result_description}</td>
                                                    <td>{payment.customer_name}</td>
                                                    <td>KES {payment.amount}</td>
                                                    <td>{payment.phone_number}</td>
                                                    <td>{payment.order_id}</td>
                                                    <td>{payment.created_at.substring(0, 10)}</td>
                                                    <td>{payment.request_status === 'pending' ?
                                                        (<PillBadge variant="warning">Pending</PillBadge>) : (
                                                            <PillBadge variant="success">Paid</PillBadge>
                                                        )
                                                    }</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    {pages > 1 && (
                                        <Paginate
                                            total={pages}
                                            current={page}
                                            onChangePage={handleChangePage} />
                                    )}
                                </div>
                            )}

                </Card.Body>

            </Card>

        </div>
    )
}

export default PaymentListScreen
