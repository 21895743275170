import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import "react-bootstrap-submenu/dist/index.css"
import App from './App';
import './css/bootstrap.min.css'
import './css/custom.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux'
import store from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
