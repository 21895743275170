import {
    DOCUMENT_LIST_FAIL,
    DOCUMENT_LIST_REQUEST,
    DOCUMENT_LIST_SUCCESS,
    DOCUMENT_DETAILS_REQUEST,
    DOCUMENT_DETAILS_SUCCESS,
    DOCUMENT_DETAILS_FAIL,

    DOCUMENT_DELETE_REQUEST,
    DOCUMENT_DELETE_SUCCESS,
    DOCUMENT_DELETE_FAIL,

    DOCUMENT_CREATE_REQUEST,
    DOCUMENT_CREATE_SUCCESS,
    DOCUMENT_CREATE_FAIL,
    DOCUMENT_CREATE_RESET,

    DOCUMENT_CREATE_REVIEW_REQUEST,
    DOCUMENT_CREATE_REVIEW_SUCCESS,
    DOCUMENT_CREATE_REVIEW_FAIL,
    DOCUMENT_CREATE_REVIEW_RESET,

    DOCUMENT_TOP_REQUEST,
    DOCUMENT_TOP_SUCCESS,
    DOCUMENT_TOP_FAIL,

    DOCUMENT_UPDATE_REQUEST,
    DOCUMENT_UPDATE_SUCCESS,
    DOCUMENT_UPDATE_FAIL,
    DOCUMENT_UPDATE_RESET,
} from "../constants/documentConstants"

// load data, error -> then manipulat the state and updates the store
export const documentListReducer = (state = { documents: [] }, action) => {
    switch (action.type) {
        case DOCUMENT_LIST_REQUEST:
            return { loading: true, ...state } // documents:[]

        case DOCUMENT_LIST_SUCCESS:
            return {
                loading: false,
                documents: action.payload.documents,
                pagination: action.payload.pagination,
                page: action.payload.pagination.page,
                pages: action.payload.pagination.num_pages
            }

        case DOCUMENT_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


// load data, error -> then manipulat the state and updates the store
export const documentDetailsReducer = (state = { document: { reviews: [], review_list: { reviews: [] } } }, action) => {
    switch (action.type) {
        case DOCUMENT_DETAILS_REQUEST:
            return { loading: true, ...state }

        case DOCUMENT_DETAILS_SUCCESS:
            return { loading: false, document: action.payload }

        case DOCUMENT_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

// load data, error -> then manipulat the state and updates the store
export const documentDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case DOCUMENT_DELETE_REQUEST:
            return { loading: true }

        case DOCUMENT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case DOCUMENT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

// load data, error -> then manipulat the state and updates the store
export const documentCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case DOCUMENT_CREATE_REQUEST:
            return { loading: true }

        case DOCUMENT_CREATE_SUCCESS:
            return { loading: false, success: true, document: action.payload }

        case DOCUMENT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case DOCUMENT_CREATE_RESET:
            return {}

        default:
            return state
    }
}

// load data, error -> then manipulat the state and updates the store
export const documentReviewCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case DOCUMENT_CREATE_REVIEW_REQUEST:
            return { loading: true }

        case DOCUMENT_CREATE_REVIEW_SUCCESS:
            return { loading: false, success: true }

        case DOCUMENT_CREATE_REVIEW_FAIL:
            return { loading: false, error: action.payload }

        case DOCUMENT_CREATE_REVIEW_RESET:
            return {}

        default:
            return state
    }
}

// load data, error -> then manipulat the state and updates the store
export const documentTopRatedReducer = (state = { documents: [] }, action) => {
    switch (action.type) {
        case DOCUMENT_TOP_REQUEST:
            return { loading: true, documents: []  }

        case DOCUMENT_TOP_SUCCESS:
            return { loading: false, documents: action.payload }

        case DOCUMENT_TOP_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const documentUpdateReducer = (state = {}, action) => {

    switch(action.type) {

        case DOCUMENT_UPDATE_REQUEST:
            return {
                loading: true,
            }
        case DOCUMENT_UPDATE_SUCCESS:
            return {
                loading: false,
                document: action.payload,
                success: true,
            }

        case DOCUMENT_UPDATE_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case DOCUMENT_UPDATE_RESET:
            return {}
            
        default:
            return state

    }
}