import { config } from "@fortawesome/fontawesome-svg-core"
import axios from "axios"
import { BACKEND_URL } from "../../utils/utils"
import { 
    DOCUMENT_LIST_FAIL,
    DOCUMENT_LIST_REQUEST,
    DOCUMENT_LIST_SUCCESS,
    DOCUMENT_DETAILS_REQUEST,
    DOCUMENT_DETAILS_SUCCESS,
    DOCUMENT_DETAILS_FAIL,

    DOCUMENT_DELETE_REQUEST,
    DOCUMENT_DELETE_SUCCESS,
    DOCUMENT_DELETE_FAIL,

    DOCUMENT_CREATE_REQUEST,
    DOCUMENT_CREATE_SUCCESS,
    DOCUMENT_CREATE_FAIL,
    DOCUMENT_CREATE_RESET,

    DOCUMENT_CREATE_REVIEW_REQUEST,
    DOCUMENT_CREATE_REVIEW_SUCCESS,
    DOCUMENT_CREATE_REVIEW_FAIL,

    DOCUMENT_TOP_REQUEST,
    DOCUMENT_TOP_SUCCESS,
    DOCUMENT_TOP_FAIL,
    DOCUMENT_UPDATE_REQUEST,
    DOCUMENT_UPDATE_FAIL,
    DOCUMENT_UPDATE_SUCCESS,
    DOCUMENT_UPDATE_RESET,
} from "../constants/documentConstants"
// dispatch is used to dispatch the actions in reducers

// dispatch is used to dispatch the actions in reducers
export const listDocuments = (queryParameters) => async (dispatch) => {
    
    try {
        dispatch({type: DOCUMENT_LIST_REQUEST})

        const { data } = await axios.get(`${BACKEND_URL}/api/documents?${queryParameters}`)

        dispatch({
            type: DOCUMENT_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DOCUMENT_LIST_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const getDocumentDetails = (id) => async (dispatch) => {
    try {
        dispatch({type: DOCUMENT_DETAILS_REQUEST})

        const { data } = await axios.get(`${BACKEND_URL}/api/documents/${id}`, config)

        dispatch({
            type: DOCUMENT_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DOCUMENT_DETAILS_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const deleteDocument = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: DOCUMENT_DELETE_REQUEST
        })

        const {
            //getting user info from userLogin in state
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': userInfo.token
            }
        }

        await axios.delete(
            `${BACKEND_URL}/api/documents/${id}`,
            config
        )

        dispatch({
            type: DOCUMENT_DELETE_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: DOCUMENT_DELETE_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const createDocument = (documentData) => async(dispatch, getState) => {
    try {
        dispatch({
            type: DOCUMENT_CREATE_REQUEST
        })

        const formData = new FormData()

        formData.append("file", documentData.file)
        formData.append("grade", documentData.grade)
        formData.append("document_level", documentData.document_level)
        formData.append("document_type_id", documentData.document_type_id)
        formData.append("term", documentData.term)
        formData.append("image", documentData.image)
        formData.append("description", documentData.description)
        formData.append("price", documentData.price)
        formData.append("subject", documentData.subject)
        formData.append("brand", documentData.brand)

        const {
            //getting user info from userLogin in state
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Auth-Token': userInfo.token
            }
        }

        const { data } = await axios.post(
            `${BACKEND_URL}/api/documents`,
            formData,
            config
        )

        dispatch({
            type: DOCUMENT_CREATE_SUCCESS,
            payload: data,
        })

        dispatch({type: DOCUMENT_CREATE_RESET})

    } catch (error) {
        dispatch({
            type: DOCUMENT_CREATE_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const createDocumentReview = (review) => async(dispatch, getState) => {
    try {
        dispatch({
            type: DOCUMENT_CREATE_REVIEW_REQUEST
        })
        
        const {
            //getting user info from userLogin in state
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': userInfo.token
            }
        }

        const { data } = await axios.post(
            `${BACKEND_URL}/api/reviews`,
            review,
            config
        )

        dispatch({
            type:  DOCUMENT_CREATE_REVIEW_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: DOCUMENT_CREATE_REVIEW_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

// dispatch is used to dispatch the actions in reducers
export const listTopDocuments = () => async (dispatch) => {
    try {
        dispatch({type: DOCUMENT_TOP_REQUEST})

        const { data } = await axios.get(`${BACKEND_URL}/api/documents/featured`)

        dispatch({
            type: DOCUMENT_TOP_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DOCUMENT_TOP_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const listCategoryDocuments = (id, queryParameters) => async (dispatch) => {
    try {

        dispatch({type: DOCUMENT_LIST_REQUEST})

        const { data } = await axios.get(`${BACKEND_URL}/api/category/${id}/documents?${queryParameters}`)

        dispatch({
            type: DOCUMENT_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DOCUMENT_LIST_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const updateDocument = (document, id) => async(dispatch, getState) => {

    try {
        dispatch({
            type: DOCUMENT_UPDATE_REQUEST
        })

        const {
            //getting user info from userLogin in state
            userLogin: { userInfo },
        } = getState()

        console.log("Document")
        console.log(document)

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': userInfo.token
            }
        }

        const { data } = await axios.put(
            `${BACKEND_URL}/api/documents/${id}`,
            document,
            config
        )

        console.log("DATA")
        console.log(data)

        dispatch({
            type: DOCUMENT_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch({
            type: DOCUMENT_UPDATE_RESET,
        })

    } catch (error) {
        dispatch({
            type: DOCUMENT_UPDATE_FAIL,
            payload: error.response && error.response.data.error_message
            ? error.response.data.error_message
            : error.message
        })
    }
}
