import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Card, Container } from 'react-bootstrap'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../store/actions/userActions'
import FormContainer from '../../components/FormContainer'
// import FormContainer from '../../components/FormContainer'


function LoginScreen() {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const redirect = searchParams.get('redirect') ? searchParams.get('redirect') : '/'

  //initializing the dispatch used to dispatch actions
  const dispatch = useDispatch()

  // getting the state
  const userLogin = useSelector(state => state.userLogin)

  const { error, loading, userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      if (redirect.length > 1) {
        navigate(`/${redirect}`)
      } else {
        navigate(redirect)
      }
    }
  }, [navigate, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(username, password))
  }
  return (
    <div>
      <FormContainer>
        <Card className='p-4 shadow mb-4'>
          <Card.Header>
            <Card.Title>
              Sign In
            </Card.Title>
          </Card.Header>
          <Card.Body className='d-flex flex-column px-3'>
          {error && <Message variant='danger'>{error}</Message>}
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="email">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}>

              </Form.Control>
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}>

              </Form.Control>
            </Form.Group>

            <Button
              type="submit"
              variant='primary'
              className="mb-4 w-100 my-3"
              size="lg">
              Sign In
            </Button>
          </Form>

          <div className="d-flex justify-content-between mx-4 mb-4">
            <Form.Check name='flexCheck' value='' id='flexCheckDefault' label='Remember me' />
            <a href="!#">Forgot password?</a>
          </div>

          <Row className='py-3'>
            <Col>
              New User? <Link
                to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                Register
              </Link>
            </Col>

          </Row>

        </Card.Body>
        </Card>
      </FormContainer>
    </div>
  )
}

export default LoginScreen
