import React, { useState } from 'react'
import { Button, Form, Col, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'


function SearchBox() {
    const [term, setTerm] = useState('')

    const navigate = useNavigate()
    const location = useLocation();
    

    const submitHandler = (e) => {
        e.preventDefault()

        if (term) {
            navigate(`${location.pathname}?term=${term}`)
        } else {
            navigate(location.pathname)
        }
    }

    return (
        <Form onSubmit={submitHandler}  inline="true">
            <Row>
                <Col md={8} className="mr-0">
                    <Form.Control
                        type='text'
                        name='term'
                        onChange={(e) => setTerm(e.target.value)}
                        className='mr-sm-2 ml-sm-5'>
                    </Form.Control>

                </Col>
                <Col md={4} className="ml-0">
                    <Button
                        type='submit'
                        variant='outline-primary'
                        className='p-2'
                    >
                        Search
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default SearchBox
