import React from 'react'
import { Card } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Rating from './Rating'
import { Link } from 'react-router-dom'
import { truncate } from '../utils/utils'


function Document({ document }) {

    const renderTooltip = (props) => (
        <Tooltip placement='top' id="button-tooltip" {...props}>
         {document.name}
        </Tooltip>
      );

  return (

    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}>
      <Card className='my-2 p-2 rounded'>
      <Link to={`/documents/${document.id}`}>
        <Card.Img src={document.cover_image} />
        </Link>

        <Card.Body>
            <Link to={`/documents/${document.id}`}>
                <Card.Title as="div"> 
                    <strong>{truncate(document.name, 20)}</strong>
                </Card.Title>
            </Link>
            <Card.Text as="div">
                <div className='my-2'>
                    <Rating value={document.ratings} text={`${document.num_reviews} reviews`} color={'#f8e825'}/>
                </div>

            </Card.Text>

            <Card.Text as="h6">
                Ksh {document.price }
            </Card.Text>
        </Card.Body>
    </Card>
    </OverlayTrigger>
  )
}

export default Document
