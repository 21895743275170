import React, { useCallback, useEffect } from 'react'
import { Table, Button, Card } from 'react-bootstrap'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { listOrders } from '../../store/actions/orderActions'
import Paginate from '../../components/Paginate'


function OrderListScreen() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [queryParameters, setSearchParams] = useSearchParams()
    //const term = queryParameters.get("term")

    // getting state
    const orderList = useSelector(state => state.orderList)
    const { loading, error, orders, page, pages } = orderList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (userInfo && userInfo.is_admin) {
            dispatch(listOrders(queryParameters))
        } else {
            navigate('/login')
        }
    }, [dispatch, navigate, userInfo, queryParameters])

    // TODO: Add to utils and export the function
    // TODO: Make it abstract on the param value
    const handleChangePage = useCallback((page) => {

        let params = Object.fromEntries([...queryParameters])
        if (params.hasOwnProperty("page")) {
            params.page = page
        } else {
            params["page"] = page
        }

        setSearchParams(createSearchParams(params))
    }, [setSearchParams, queryParameters])

    return (
        <div>
            <Card className="shadow mb-2">
                <Card.Header>
                    <Card.Title>
                        Orders
                    </Card.Title>
                </Card.Header>
                <Card.Body className='d-flex flex-column px-3'>
                    {loading
                        ? (<Loader />)
                        : error
                            ? (<Message variant='danger'>{error}</Message>)
                            : (
                                <div>

                                    <Table striped responsive bordered hover className='table-sm'>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Order Date</th>
                                                <th>Total</th>
                                                <th>Paid</th>
                                                <th>Delivered</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders.map(order => (
                                                <tr key={order.id}>
                                                    <td>{order.id}</td>
                                                    <td>{order.created_at.substring(0, 10)}</td>
                                                    <td>KES {order.total_price}</td>
                                                    <td>{order.is_paid ? order.paid_at.substring(0, 10) : (
                                                        <i className='fas fa-times' style={{ color: "red" }}></i>
                                                    )}</td>
                                                    <td>{order.is_delivered ? order.is_delivered : (
                                                        <i className='fas fa-times' style={{ color: "red" }}></i>
                                                    )}</td>
                                                    <td>
                                                        <LinkContainer to={`/order/${order.id}`} >
                                                            <Button className='btn-sm'>Details</Button>
                                                        </LinkContainer>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    {pages > 1 && (
                                        <Paginate
                                            total={pages}
                                            current={page}
                                            onChangePage={handleChangePage} />
                                    )}

                                </div>
                            )}
                </Card.Body>

            </Card>
        </div>
    )
}

export default OrderListScreen
