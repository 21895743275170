import React, { useState, useEffect, useCallback } from 'react'
import { Table, Button, Card, Row, Col } from 'react-bootstrap'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { deleteMail, listMailRecipients, listMails } from '../../store/actions/mailActions'
// import FormContainer from '../../components/FormContainer'
import PillBadge from '../../components/PillBadge'
import MailRecipientModal from '../../components/MailRecipientModal'
import ListGroup from 'react-bootstrap/ListGroup';
import Paginate from '../../components/Paginate'


function MailsListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // locacl level state
  const  [showModal, setShowModal] = useState(false);
  const [mail_id, setMailID] = useState(1);

  const [queryParameters, setSearchParams] = useSearchParams()

  // getting state
  const mailList = useSelector(state => state.mailList)
  const { loading, error, emails, page, pages } = mailList

  const mailRecipientList = useSelector(state => state.mailRecipientList)
  const { loading: recipientLoading, error: recipientError, recipients } = mailRecipientList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const mailDelete = useSelector(state => state.mailDelete)
  const { success: successDelete } = mailDelete


  useEffect(() => {
    if (userInfo && userInfo.is_admin) {
      dispatch(listMails(queryParameters))
    } else {
      navigate('/login')
    }
  }, [dispatch, navigate, successDelete, userInfo, queryParameters])

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteMail(id))
    }
  }

  const handleShowModal = (mail_id) => {
    console.log("Modal ID:", mail_id)
    dispatch(listMailRecipients(mail_id))

    setShowModal(true)
  }

  const handleChangePage = useCallback((page) => {

    let params = Object.fromEntries([...queryParameters])
    if (params.hasOwnProperty("page")) {
        params.page = page
    } else {
        params["page"] = page
    }

    setSearchParams(createSearchParams(params))
}, [setSearchParams, queryParameters])

  return (
    <div>
      <Card className="shadow mb-2">
        <Card.Header>
          <Card.Title>
            E-Mails
          </Card.Title>
        </Card.Header>
        <Card.Body className='d-flex flex-column px-3'>
          {loading
            ? (<Loader />)
            : error
              ? (<Message variant='danger'>{error}</Message>)
              : (
                <div>
                    <Table striped responsive bordered hover className='table-sm'>
                      <thead>
                        <tr>
                          <th>Subject</th>
                          <th>User ID</th>
                          <th>Message</th>
                          <th>Sent At</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {emails.map(email => (
                          <tr key={email.id}>
                            <td>{email.subject}</td>
                            <td>{email.user_id}</td>
                            <td>{email.message}</td>
                            <td>{email.created_at.substring(0, 10)}</td>
                            <td>{email.is_sent ? (
                              <PillBadge variant="success">Sent</PillBadge>
                            ) : (
                              <PillBadge variant="warning">Pending</PillBadge>
                            )}</td>
                            <td>
                              <Button 
                                className='btn-sm' 
                                variant='light'
                                // onMouseOver={() => setMailID(email.id) }
                                onClick={() => handleShowModal(email.id)}
                                >
                                <i className='fas fa-eye'></i>
                              </Button>

                              <Button className='btn-sm' variant='danger' onClick={() => deleteHandler(email.id)}>
                                <i className='fas fa-trash ml-4'></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {pages > 1 && (
                        <Paginate
                            total={pages}
                            current={page}
                            onChangePage={handleChangePage} />
                    )}
                </div>
                
              )}
              <Row>
            <Col className='justify-content-end text-right'>
              <LinkContainer to={`/admin/mails/create`}>
                <Button className='my-3'>
                  <i className='fas fa-plus'></i> Send Mail
                </Button>
              </LinkContainer>
            </Col>
          </Row>

        </Card.Body>
      </Card>
      <Row>
        <Modal
          size="md"
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="example-modal-sizes-title-md"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-md">
                Mail Recipients
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {recipients.length === 0 
            ? (
              <p>No Email Recipients</p>
            ) : (
              <ListGroup as="ol" numbered>
                  {
                      recipients.map((recipient, index) => (
                          <ListGroup.Item
                              as="li"
                              key={index}
                              className="d-flex justify-content-between align-items-start"
                          >
                              <div className="ms-2 me-auto">
                              <div className="fw-bold">{recipient.name}</div>
                              {recipient.email}
                              </div>
                              {recipient.status === "delivered" ? (
                                <PillBadge variant="success">Delivered</PillBadge>
                              ) : recipient.status === "bounced" ? ( 
                                  <PillBadge variant="danger">Failed</PillBadge>
                                  ) 
                                    : recipient.status === "queued" ? (
                                      <PillBadge variant="secondary">Queued</PillBadge>
                                      )
                                      :(
                                        <PillBadge variant="warning">Pending</PillBadge>
                                      )}
                          </ListGroup.Item>
                      ))
                  }
              </ListGroup>)
              }
            </Modal.Body>
        </Modal>
      </Row>
    </div>
  )
}

export default MailsListScreen
