import { 
    DOCUMENT_CATEGORY_LIST_REQUEST,
    DOCUMENT_CATEGORY_LIST_SUCCESS,
    DOCUMENT_CATEGORY_LIST_FAIL,

    DOCUMENT_CATEGORY_DELETE_REQUEST,
    DOCUMENT_CATEGORY_DELETE_SUCCESS,
    DOCUMENT_CATEGORY_DELETE_FAIL,

    // DOCUMENT_CATEGORY_EDIT_REQUEST,
    // DOCUMENT_CATEGORY_EDIT_SUCCESS,
    // DOCUMENT_CATEGORY_EDIT_FAIL,

    DOCUMENT_CATEGORY_CREATE_REQUEST,
    DOCUMENT_CATEGORY_CREATE_SUCCESS,
    DOCUMENT_CATEGORY_CREATE_FAIL,
    DOCUMENT_CATEGORY_CREATE_RESET,

    DOCUMENT_CATEGORY_DETAILS_REQUEST,
    DOCUMENT_CATEGORY_DETAILS_SUCCESS,
    DOCUMENT_CATEGORY_DETAILS_FAIL,
} from "../constants/categoryConstants";

export const categoryListReducer = (state = { categories: []}, action) =>  {

    switch(action.type) {
        case DOCUMENT_CATEGORY_LIST_REQUEST:
            return { loading: true, ...state }

        case DOCUMENT_CATEGORY_LIST_SUCCESS:
            return { loading: false, categories: action.payload }

        case DOCUMENT_CATEGORY_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const categoryDeleteReducer = ( state = {}, action) => {
    switch(action.type) {
        case DOCUMENT_CATEGORY_DELETE_REQUEST:
            return { loading: true }
        case DOCUMENT_CATEGORY_DELETE_SUCCESS:
            return { loading: false , success: true }

        case DOCUMENT_CATEGORY_DELETE_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

// load data, error -> then manipulat the state and updates the store
export const categoryCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case DOCUMENT_CATEGORY_CREATE_REQUEST:
            return { loading: true }

        case DOCUMENT_CATEGORY_CREATE_SUCCESS:
            return { loading:false, success: true, category: action.payload }

        case DOCUMENT_CATEGORY_CREATE_FAIL:
            return { loading:false, error:action.payload }

        case DOCUMENT_CATEGORY_CREATE_RESET:
            return { }
        
        default:
            return state
    }
}

// load data, error -> then manipulat the state and updates the store
export const categoryDetailsReducer = (state = { category:{} }, action) => {
    switch (action.type) {
        case DOCUMENT_CATEGORY_DETAILS_REQUEST:
            return { loading: true, ...state }

        case DOCUMENT_CATEGORY_DETAILS_SUCCESS:
            return { loading:false, category:action.payload }

        case DOCUMENT_CATEGORY_DETAILS_FAIL:
            return { loading:false, error:action.payload }
        
        default:
            return state
    }
}
