import React from 'react'

function Page404() {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-center mt-4">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"> <span className="text-danger">Opps!</span> Item not Found.</p>
                <p className="lead">
                    We are working around the clock to bring the item soon.
                  </p>
                  <p className="lead">
                  Thank you for staying with us. StudyRepo is your trusted partner!
                  </p>
                <a href="/" className="btn btn-primary">Go Home</a>
            </div>
        </div>
      
    </div>
  )
}

export default Page404
