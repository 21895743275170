import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../components/FormContainer'
import { getUserDetails, updateUser } from '../../store/actions/userActions'
import { USER_UPDATE_SUCCESS } from '../../store/constants/userConstants'
//import { getUserDetails } from '../../store/actions

function EditUserScreen() {

  // url parameters
  const { id } = useParams()

  //initializing the dispatch used to dispatch actions
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // form values
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [is_admin, setIsAdmin] = useState(false)

  const [message, setMessage] = useState('')

  // getting the state
  const userDetails = useSelector(state => state.userDetails)
  const { error, loading, user } = userDetails

  const userUpdate = useSelector(state => state.userUpdate)
  const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = userUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_SUCCESS })
      navigate('/admin/users')
    } else {
      if (!user.first_name || user.id !== Number(id)) {
        dispatch(getUserDetails(id))
      } else {
        setFirstName(user.first_name)
        setLastName(user.last_name)
        setEmail(user.email)
        setIsAdmin(user.is_admin)
      }
    }
  }, [id, user, dispatch, successUpdate, navigate])


  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateUser({ 
        id: user.id,
        first_name,
        last_name,
        is_admin,
      })
    )
  }


  return (
    <div>
      <Link to="/" className='btn btn-light my-3'> Go Back</Link>
      <FormContainer>
        <h1>Edit User</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
          : (
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="first_name">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter First Name"
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}>

                </Form.Control>
              </Form.Group>

              <Form.Group controlId="last_name">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Last Name"
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}>

                </Form.Control>
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}>

                </Form.Control>
              </Form.Group>

              <Form.Group controlId="isadmin">
                <Form.Label>Password</Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Is Admin"
                  checked={is_admin}
                  onChange={(e) => setIsAdmin(e.target.checked)}>

                </Form.Check>
              </Form.Group>

              <Button
                type="submit"
                variant='primary'
                className='my-3'>
                Update
              </Button>
            </Form>
          )}
      </FormContainer>
    </div>
  )
}

export default EditUserScreen
