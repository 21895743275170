import React, { useState, useEffect, useCallback } from 'react'
import { Form, Button, Row, Col, Table, Card } from 'react-bootstrap'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { getUserOrderList } from '../../store/actions/orderActions'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails, updateUserProfile } from '../../store/actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../../store/constants/userConstants'
import Paginate from '../../components/Paginate'


function ProfileScreen() {
    // form values
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    //const [searchParams] = useSearchParams()

    //initializing the dispatch used to dispatch actions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [queryParameters, setSearchParams] = useSearchParams()

    // getting the state
    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const { success } = userUpdateProfile

    const userOrderList = useSelector(state => state.userOrderList)
    const { loading: loadingOrders, error: errorOrders, orders, pages, page } = userOrderList

    useEffect(() => {
        if (!userInfo) {
            navigate('/login')
        } else {
            if (!user || !user.first_name || success || userInfo.user_id !== user.id) {
                // clear the user profile state
                dispatch({ type: USER_UPDATE_PROFILE_RESET })
                dispatch(getUserDetails(user.user_id))
                dispatch(getUserOrderList())
            } else {
                setFirstName(user.first_name)
                setLastName(user.last_name)
                setEmail(user.email)
            }
        }
    }, [dispatch, navigate, userInfo, user, success])

    const submitHandler = (e) => {
        e.preventDefault()

        if (password !== confirmPassword) {
            setMessage("Passwords do not match")
        } else {
            dispatch(updateUserProfile({
                'id': user.id,
                'first_name': first_name,
                'last_name': last_name,
                'email': email,
                'password': password,
            }))

            setMessage("")
        }
    }


    const handleChangePage = useCallback((page) => {
        let params = Object.fromEntries([...queryParameters])
        if (params.hasOwnProperty("page")) {
            params.page = page
        } else {
            params["page"] = page
        }

        setSearchParams(createSearchParams(params))
    }, [setSearchParams, queryParameters])

    return (
        <Row>
            <Col md={4}>
                <Card className='p-1 shadow mb-4'>
                    <Card.Header>
                        <Card.Title>
                            User Profile
                        </Card.Title>
                    </Card.Header>
                    <Card.Body className='d-flex flex-column px-3'>
                        {message && <Message variant='danger'>{message}</Message>}
                        {error && <Message variant='danger'>{error}</Message>}
                        {loading && <Loader />}
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId="first_name">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter First Name"
                                    value={first_name}
                                    onChange={(e) => setFirstName(e.target.value)}>

                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="last_name">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter Last Name"
                                    value={last_name}
                                    onChange={(e) => setLastName(e.target.value)}>

                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="email">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Enter Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}>

                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}>

                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="password_confirm">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}>

                                </Form.Control>
                            </Form.Group>

                            <Button
                                type="submit"
                                variant='primary'
                                className='my-3'>
                                Update
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>

            <Col md={8}>
                <Card className='p-4 shadow mb-4'>
                    <Card.Header>
                        <Card.Title>
                            My Orders
                        </Card.Title>
                    </Card.Header>
                    <Card.Body className='d-flex flex-column px-3'>
                        {loadingOrders ? (
                            <Loader />
                        ) : errorOrders ? (
                            <Message variant='danger'>{errorOrders}</Message>
                        ) : (
                            <div>
                                <Table striped responsive className='table-sm'>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Order Date</th>
                                            <th>Total</th>
                                            <th>Paid</th>
                                            <th>Delivered</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map(order => (
                                            <tr key={order.id}>
                                                <td>{order.id}</td>
                                                <td>{order.created_at.substring(0, 10)}</td>
                                                <td>KES {order.total_price}</td>
                                                <td>{order.is_paid ? order.paid_at.substring(0, 10) : (
                                                    <i className='fas fa-times' style={{ color: "red" }}></i>
                                                )}</td>
                                                <td>{order.is_delivered ? order.is_delivered : (
                                                    <i className='fas fa-times' style={{ color: "red" }}></i>
                                                )}</td>
                                                <td>
                                                    <LinkContainer to={`/order/${order.id}`} >
                                                        <Button className='btn-sm'>Details</Button>
                                                    </LinkContainer>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                {pages > 1 && (
                                    <Paginate
                                        total={pages}
                                        current={page}
                                        onChangePage={handleChangePage} />
                                )}
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default ProfileScreen
