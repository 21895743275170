import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  Row, Col } from 'react-bootstrap'
import Document from '../../components/Document'
import { listDocuments } from '../../store/actions/documentActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { useSearchParams, createSearchParams } from 'react-router-dom'
import Paginate from '../../components/Paginate'
import DocumentCarousel from '../../components/DocumentCarousel'
import Page404 from '../pages/Page404'


// useDispatch is used to dispatch actions
// useSelector is used to select some parts of the state
export default function HomeScreen() {

  const dispatch = useDispatch()

  const [queryParameters, setSearchParams] = useSearchParams() // component level state
  const term = queryParameters.get("term")

  //accessing the document list from store
  const documentList = useSelector(state => state.documentList) // selects the state
 // destructures the documents list state object into individual components
  const { error, loading, documents, page, pages } = documentList
  
    useEffect(() => {
      dispatch(listDocuments(queryParameters)) // dispatched the action
    }, [dispatch, queryParameters]) // gets back result 

    const handleChangePage = useCallback((page) => {
  
      let params = Object.fromEntries([...queryParameters]) // ▶ { sort: 'name', order: 'asecnding' }
      
      if (params.hasOwnProperty("page")) {
          params.page = page
      } else {
        params["page"] = page
      }

      setSearchParams(createSearchParams(params))
    }, [setSearchParams, queryParameters])

  return (
    <div>
      {!term && <DocumentCarousel />}
      <h1>Latest Documents</h1>
      {loading ? <Loader />
        : error ? <Message variant='danger'>{error}</Message>
          :
            <div>
              <Row>
                {documents.length >= 1 ? (documents.map(document => (
                    <Col key={document.id} sm={12} md={6} lg={3} xl={2}>
                        <Document document={document} /> 
                    </Col>
                ))) : (
                  <Page404 />
                )}
              </Row>
              {pages > 1 && (
                <Paginate 
                  total={pages} 
                  current={page} 
                  onChangePage={handleChangePage}/>
              )}
            </div>
      }
    </div>
  )
}
