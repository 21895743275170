export const DOCUMENT_CATEGORY_LIST_REQUEST = 'DOCUMENT_CATEGORY_LIST_REQUEST'
export const DOCUMENT_CATEGORY_LIST_SUCCESS = 'DOCUMENT_CATEGORY_LIST_SUCCESS'
export const DOCUMENT_CATEGORY_LIST_FAIL = 'DOCUMENT_CATEGORY_LIST_FAIL'

export const DOCUMENT_CATEGORY_DELETE_REQUEST = 'DOCUMENT_CATEGORY_DELETE_REQUEST'
export const DOCUMENT_CATEGORY_DELETE_SUCCESS = 'DOCUMENT_CATEGORY_DELETE_SUCCESS'
export const DOCUMENT_CATEGORY_DELETE_FAIL = 'DOCUMENT_CATEGORY_DELETE_FAIL'

export const DOCUMENT_CATEGORY_EDIT_REQUEST = 'DOCUMENT_CATEGORY_EDIT_REQUEST'
export const DOCUMENT_CATEGORY_EDIT_SUCCESS = 'DOCUMENT_CATEGORY_EDIT_SUCCESS'
export const DOCUMENT_CATEGORY_EDIT_FAIL = 'DOCUMENT_CATEGORY_EDIT_FAIL'

export const DOCUMENT_CATEGORY_CREATE_REQUEST = 'DOCUMENT_CATEGORY_CREATE_REQUEST'
export const DOCUMENT_CATEGORY_CREATE_SUCCESS = 'DOCUMENT_CATEGORY_CREATE_SUCCESS'
export const DOCUMENT_CATEGORY_CREATE_FAIL = 'DOCUMENT_CATEGORY_CREATE_FAIL'
export const DOCUMENT_CATEGORY_CREATE_RESET = 'DOCUMENT_CATEGORY_CREATE_RESET'

export const DOCUMENT_CATEGORY_DETAILS_REQUEST = 'DOCUMENT_CATEGORY_DETAILS_REQUEST'
export const DOCUMENT_CATEGORY_DETAILS_SUCCESS = 'DOCUMENT_CATEGORY_DETAILS_SUCCESS'
export const DOCUMENT_CATEGORY_DETAILS_FAIL = 'DOCUMENT_CATEGORY_DETAILS_FAIL'
