import { PAYMENT_LIST_FAIL, PAYMENT_LIST_REQUEST, PAYMENT_LIST_REST, PAYMENT_LIST_SUCCESS } from "../constants/paymentConstants";

export const paymentListReducer = (state = { payments: [] }, action) => {

    switch (action.type) {
        case PAYMENT_LIST_REQUEST:
            return {
                loading: true,
                ...state
            }

        case PAYMENT_LIST_SUCCESS:
            return {
                loading: false,
                payments: action.payload.payment_requests,
                pagination: action.payload.pagination,
                pages: action.payload.pagination.num_pages,
                page: action.payload.pagination.page
            }

        case PAYMENT_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case PAYMENT_LIST_REST:
            return {}

        default:
            return state
    }
}