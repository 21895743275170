import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Card } from 'react-bootstrap'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../components/FormContainer'
import { register } from '../../store/actions/userActions'


function RegisterScreen() {

  // form values
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [number, setNumber] = useState('')
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [password_confirmation, setConfirmPassword] = useState('')
  const [message, setMessage] = useState('')

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const redirect = searchParams.get('redirect') ? searchParams.get('redirect')[1] : '/'

  //initializing the dispatch used to dispatch actions
  const dispatch = useDispatch()

  // getting the state
  const userRegister = useSelector(state => state.userRegister)

  const { error, loading, userInfo, success } = userRegister

  useEffect(() => {
    if (userInfo || success) {
      navigate("/login")
    }
  }, [navigate, userInfo, redirect, success])


  const submitHandler = (e) => {
    e.preventDefault()

    if (password !== password_confirmation) {
      setMessage("Passwords do not match")
    } else {
      dispatch(register(first_name, last_name, number, username, email, password, password_confirmation))
    }
  }


  return (
    <FormContainer>
      <Card className='p-4 shadow mb-4'>
        <Card.Header>
          <Card.Title>
            Sign Up
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {message && <Message variant='danger'>{message}</Message>}
          {error && <Message variant='danger'>{error}</Message>}
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="first_name">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter First Name"
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}>

              </Form.Control>
            </Form.Group>

            <Form.Group controlId="last_name">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter Last Name"
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}>

              </Form.Control>
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Enter Phone Number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}>

              </Form.Control>
            </Form.Group>

            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}>

              </Form.Control>
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}>

              </Form.Control>
            </Form.Group>

            <Form.Group controlId="password_confirm">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Confirm Password"
                value={password_confirmation}
                onChange={(e) => setConfirmPassword(e.target.value)}>

              </Form.Control>
            </Form.Group>

            <Button
              type="submit"
              variant='primary'
              className="mb-4 w-100 my-3">
              Register
            </Button>
          </Form>

          <Row className='py-3'>
            <Col>
              Have an Account? <Link
                to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                Sign In
              </Link>
            </Col>

          </Row>
        </Card.Body>
      </Card>
    </FormContainer>
  )
}

export default RegisterScreen
