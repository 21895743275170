import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Document from '../../components/Document'
import { listCategoryDocuments } from '../../store/actions/documentActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { useSearchParams, createSearchParams, useParams } from 'react-router-dom'
import Paginate from '../../components/Paginate'
import SideBar from '../../components/Sidebar'
import DocumentSearchSelect from '../../components/DocumentSearchSelect'
import Page404 from '../pages/Page404'

// useDispatch is used to dispatch actions
// useSelector is used to select some parts of the state
export default function DocumentsFilterScreen() {

  const dispatch = useDispatch()

  const { id } = useParams()

  const [queryParameters, setSearchParams] = useSearchParams()
  //const term = queryParameters.get("term")

  //accessing the document list from store
  const documentList = useSelector(state => state.documentList) // selects the state

  // destructures the documents list state object into individual components
  const { error, loading, documents, page, pages } = documentList

  useEffect(() => {
    dispatch(listCategoryDocuments(id, queryParameters)) // dispatched the action
  }, [dispatch, queryParameters, id]) // gets back result 

  const handleChangePage = useCallback((page) => {

    let params = Object.fromEntries([...queryParameters]) // ▶ { sort: 'name', order: 'asecnding' }
    if (params.hasOwnProperty("page")) {
      params.page = page
    } else {
      params["page"] = page
    }

    setSearchParams(createSearchParams(params))

  }, [setSearchParams, queryParameters])

  const handleChangeTerm = useCallback((e) => {

    let term = e.target.value
    let params = Object.fromEntries([...queryParameters]) // ▶ { sort: 'name', order: 'asecnding' }

    if (params.hasOwnProperty("school_term")) {
      params.school_term = term
    } else {
      params["school_term"] = term
    }

    setSearchParams(createSearchParams(params))

  }, [setSearchParams, queryParameters])

  const handleChangeSubject = useCallback((e) => {

    let subject = e.target.value
    let params = Object.fromEntries([...queryParameters]) // ▶ { sort: 'name', order: 'asecnding' }

    if (params.hasOwnProperty("subject")) {
      params.subject = subject
    } else {
      params["subject"] = subject
    }

    setSearchParams(createSearchParams(params))

  }, [setSearchParams, queryParameters])



  return (
    <div>
      <Row>
        <Col md={2}>
          <SideBar />
        </Col>

        <Col md={10}>
        <h2>Document Search </h2> <hr />
          {loading ? <Loader />
            : error ? <Message variant='danger'>{error}</Message>
              :
              <div>
                <Row>
                <DocumentSearchSelect handleChangeTerm={handleChangeTerm} handleChangeSubject={handleChangeSubject} /> <br />
                  {documents.length >=1 ? (documents.map(document => (
                    <Col key={document.id} sm={8} md={4} lg={2} xl={2}>
                      <Document document={document} />
                    </Col>
                  ))) : (
                    <Page404 />
                  )}
                </Row>
                {pages > 1 && (
                  <Paginate
                    total={pages}
                    current={page}
                    onChangePage={handleChangePage} />
                )}
              </div>
          }
        </Col>
      </Row>
    </div>
  )
}
