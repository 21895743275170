export const DOCUMENT_LIST_REQUEST = 'DOCUMENT_LIST_REQUEST'
export const DOCUMENT_LIST_SUCCESS = 'DOCUMENT_LIST_SUCCESS'
export const DOCUMENT_LIST_FAIL = 'DOCUMENT_LIST_FAIL'

export const DOCUMENT_DETAILS_REQUEST = 'DOCUMENT_DETAILS_REQUEST'
export const DOCUMENT_DETAILS_SUCCESS = 'DOCUMENT_DETAILS_SUCCESS'
export const DOCUMENT_DETAILS_FAIL = 'DOCUMENT_DETAILS_FAIL'

export const DOCUMENT_DELETE_REQUEST = 'DOCUMENT_DELETE_REQUEST'
export const DOCUMENT_DELETE_SUCCESS = 'DOCUMENT_DELETE_SUCCESS'
export const DOCUMENT_DELETE_FAIL = 'DOCUMENT_DELETE_FAIL'

export const DOCUMENT_CREATE_REQUEST = 'DOCUMENT_CREATE_REQUEST'
export const DOCUMENT_CREATE_SUCCESS = 'DOCUMENT_CREATE_SUCCESS'
export const DOCUMENT_CREATE_FAIL = 'DOCUMENT_CREATE_FAIL'
export const DOCUMENT_CREATE_RESET = 'DOCUMENT_CREATE_RESET'

export const DOCUMENT_CREATE_REVIEW_REQUEST = 'DOCUMENT_CREATE_REVIEW_REQUEST'
export const DOCUMENT_CREATE_REVIEW_SUCCESS = 'DOCUMENT_CREATE_REVIEW_SUCCESS'
export const DOCUMENT_CREATE_REVIEW_FAIL = 'DOCUMENT_CREATE_REVIEW_FAIL'
export const DOCUMENT_CREATE_REVIEW_RESET = 'DOCUMENT_CREATE_REVIEW_RESET'

export const DOCUMENT_TOP_REQUEST = 'DOCUMENT_TOP_REQUEST'
export const DOCUMENT_TOP_SUCCESS = 'DOCUMENT_TOP_SUCCESS'
export const DOCUMENT_TOP_FAIL = 'DOCUMENT_TOP_FAIL'

export const DOCUMENT_UPDATE_REQUEST = 'DOCUMENT_UPDATE_REQUEST'
export const DOCUMENT_UPDATE_SUCCESS = 'DOCUMENT_UPDATE_SUCCESS'
export const DOCUMENT_UPDATE_FAIL = 'DOCUMENT_UPDATE_FAIL'
export const DOCUMENT_UPDATE_RESET = 'DOCUMENT_UPDATE_RESET'
