import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Card } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../components/FormContainer'
// import { USER_UPDATE_SUCCESS } from '../../store/constants/userConstants'
import { createMail } from '../../store/actions/mailActions'
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';


function CreateMailDraft() {

    // url parameters
    // const { id } = useParams()

    //initializing the dispatch used to dispatch actions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // form values
    const [template, setTemplate] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [custom, setCustom] = useState(true)
    const [message, setMessage] = useState('')
    const [title, setTitle] = useState('')
    const [recipients, setRecipients] = useState([])
    const [subject, setSubject] = useState('')

    // getting the state
    const mailCreate = useSelector(state => state.mailCreate)
    const { error, loading, success } = mailCreate
    
    // const categoryList = useSelector(state => state.categoryList)
    // const { error: errorCategory, categories } = categoryList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {

        // get the list of categories
        //dispatch(listCategories())

        if (!userInfo && !userInfo.is_admin) {
            navigate('/login')
        } else {
            if (success) {
                navigate('/admin/mails')
            }
        }
    }, [navigate, userInfo, success, dispatch])


    const submitHandler = (e) => {
        e.preventDefault()
        // CREATE DOCUMENT
        console.log("recipients")
        console.log(recipients)

        if (template === "") {
            setCustom(false)
        }

        dispatch(createMail({
            template,
            name,
            email,
            subject,
            custom,
            message,
            title,
            recipients,
        }))
    }

    const addRecipient = (name, email) => {
        // Create a new recipient object
        const newRecipient = {
          name: name,
          email: email,
          tag: "success"
        };

        // Check if the recipients array is empty
        if (recipients.length === 0) {
          // If the array is empty, add the new recipient object as the first item
          setRecipients([newRecipient]);
        } else {

            if (hasValue(recipients, email)) {
                newRecipient["tag"] = "danger"
            }

          setRecipients(prevRecipients => [...prevRecipients, newRecipient]);
        }
      };


      const hasValue = (recipients, email)=>  {

        let hasEmail = false;

        recipients.map((recipient, index) => {
            if (recipient.email === email) {
                hasEmail = true;
            }
        })

        return hasEmail;
      }


    return (
        <div>
            <Link to="/admin/documents" className='btn btn-light my-3'> Go Back</Link>
            <FormContainer cols={12}>
                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Card className='p-4 shadow mb-4'>
                            <Card.Header>
                                <Card.Title>Create Mail</Card.Title>
                            </Card.Header>
                            <Card.Body>
                            <Form onSubmit={submitHandler}>
                                <Row>

                                    <Col md={6}>
                                        <h6>Compose Mail</h6><hr />
                                        <Form.Group controlId="title">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                placeholder="Enter Title"
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="subject">
                                            <Form.Label>Subject</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                placeholder="Enter Subject"
                                                value={subject}
                                                onChange={(e) => setSubject(e.target.value)}>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="message">
                                            <Form.Label>E-Mail</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}>
                                            </Form.Control>
                                        </Form.Group>

                                        <Row>
                                            <Col md={6}>
                                                <Button
                                                    type="submit"
                                                    variant='primary'
                                                    className='my-3'
                                                    onClick={submitHandler}>
                                                    Send Mail
                                                </Button>
                                            </Col>
                                            <Col md={6}>
                                                
                                            </Col>
                                        </Row>

                                    </Col>

                                    <Col md={6}>
                                        <h6>Recipients</h6><hr />
                                        <ListGroup as="ol" numbered>
                                            {
                                                recipients.map((recipient, index) => (
                                                    <ListGroup.Item
                                                        as="li"
                                                        key={index}
                                                        className="d-flex justify-content-between align-items-start"
                                                    >
                                                        <div className="ms-2 me-auto">
                                                        <div className="fw-bold">{recipient.name}</div>
                                                        {recipient.email}
                                                        </div>
                                                        <Badge bg={recipient.tag} pill>
                                                        1
                                                        </Badge>
                                                    </ListGroup.Item>
                                                ))
                                            }
                                        </ListGroup>

                                        <Form.Group controlId="name">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                placeholder="Enter Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                placeholder="Enter Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}>
                                            </Form.Control>
                                        </Form.Group>

                                        <Row>
                                            <Col md={6}>
                                                <Button
                                                    type="button"
                                                    variant='primary'
                                                    className='my-3'
                                                    onClick={() => addRecipient(name, email)}
                                                >
                                                    Add Recipient
                                                </Button>
                                            </Col>
                                        
                                            <Col md={6}>
                                            <Form.Group controlId="formFile" className="mb-3 my-3">
                                                <Form.Control type="file" label="Upload Contacts"/>
                                            </Form.Group>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                            </Card.Body>
                        </Card>
                    )}
            </FormContainer>
        </div>
    )
}

export default CreateMailDraft
