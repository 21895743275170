import React, { useEffect } from 'react'
import { Table, Button, Row, Col, Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
// import FormContainer from '../../components/FormContainer'
// import { truncate } from '../../utils/utils'
import { listCategories, deleteCategory } from '../../store/actions/categoryActions'



function CategoryListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // getting state
  const categoryList = useSelector(state => state.categoryList)
  const { loading, error, categories } = categoryList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const categoryDelete = useSelector(state => state.categoryDelete)
  const { loading: loadingDelete, error: errorDelete, success: successDelete } = categoryDelete


  useEffect(() => {
    if (userInfo && userInfo.is_admin) {
      dispatch(listCategories())
    } else {
      navigate('/login')
    }
  }, [dispatch, navigate, userInfo, successDelete])

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      //DELETE CATEGORIES
      dispatch(deleteCategory(id))
    }
  }

  const createCategoryHandler = (product) => {
    // create products
  }
  return (
    <div>
      <Card className='shadow mb-4'>
        <Card.Header>
          <Card.Title>Categories</Card.Title>
        </Card.Header>
        <Card.Body className='p-4'>

          {loadingDelete && <Loader />}

          {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

          {loading
            ? (<Loader />)
            : error
              ? (<Message variant='danger'>{error}</Message>)
              : (
                <Table striped responsive bordered hover className='table-sm'>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Level</th>
                      <th>Created At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {categories.map(category => (
                      <tr key={category.id}>
                        <td>{category.name}</td>
                        <td>{category.level}</td>
                        <td>{category.created_at.substring(0, 10)}</td>
                        <td>
                          <LinkContainer to={`/admin/categories/${category.id}/edit`}>
                            <Button className='btn-sm' variant='light'>
                              <i className='fas fa-edit'></i>
                            </Button>

                          </LinkContainer>
                          <Button className='btn-sm' variant='danger' onClick={() => deleteHandler(category.id)}>
                            <i className='fas fa-trash ml-4'></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}

          <Row>
            <Col className='justify-content-end text-right'>
              <LinkContainer to={`/admin/categories/create`}>
                <Button className='my-3'>
                  <i className='fas fa-plus'></i> Create Category
                </Button>
              </LinkContainer>
            </Col>
          </Row>

        </Card.Body>
      </Card>
    </div>
  )
}

export default CategoryListScreen
