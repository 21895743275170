import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_SAVE_PAYMENT_METHOD,
    CART_CLEAR_ITEMS,
} from '../constants/cartConstants'

export const cartReducer = (state = {cartItems: []}, action) => {

    switch (action.type) {
        case CART_ADD_ITEM:
            const item = action.payload
            const itemExists = state.cartItems.find(x => x.document_id === item.document_id)

            if (itemExists) {
                // if the item exists first return the origin state
                // update the cart items using map and if it exists update it with item
                // otherwise return the original document_id as 
                return {
                    ...state,
                    cartItems: state.cartItems.map(x => 
                        x.document_id === itemExists.document_id ? item : x)
                }
            } else {
                return {
                    ...state,
                    cartItems: [...state.cartItems, item]
                }
            }
        case CART_REMOVE_ITEM: 
            return {
                ...state,
                // returns a new array that does not match the passed id
                cartItems: state.cartItems.filter(x => x.document_id !== action.payload)
            }

        case CART_CLEAR_ITEMS: 
            return {
                ...state,
                // clear cart items part of the state
                cartItems: []
            }
        default:
            return state
    }
}

export const shippingReducer = (state = { shippingAddress: {} }, action) => {
    switch (action.type) {
        case CART_SAVE_SHIPPING_ADDRESS:
            return {
                ...state,
                shippingAddress: action.payload
            }

    default:
        return state
    }
}


export const paymentMethodReducer = (state = { paymentMethod: {} }, action) => {
    switch (action.type) {
        case CART_SAVE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload
            }

    default:
        return state
    }
}