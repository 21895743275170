import axios from 'axios'
import { BACKEND_URL } from '../../utils/utils'
import { CART_CLEAR_ITEMS } from '../constants/cartConstants'
import { 
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,

    ORDER_LIST_MY_REQUEST,
    ORDER_LIST_MY_SUCCESS,
    ORDER_LIST_MY_FAIL,

    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL,

    ORDER_DELIVERED_REQUEST,
    ORDER_DELIVERED_SUCCESS,
    ORDER_DELIVERED_FAIL,
} from '../constants/orderConstants'

export const createOrder = (order) => async(dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_CREATE_REQUEST
        })

        let token

        const {
            // getting user info from userLogin in state
            userLogin: { userInfo },
        } = getState()

        if (userInfo)  {
            token = userInfo.token
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': token
            }
        }

        const { data } = await axios.post(
            `${BACKEND_URL}/api/orders`,
            order,
            config
        )

        dispatch({
            type: ORDER_CREATE_SUCCESS,
            payload: data,
        })

        dispatch({
            type: CART_CLEAR_ITEMS,
        })

        localStorage.removeItem('cartItems')


    } catch (error) {
        dispatch({
            type: ORDER_CREATE_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const getOrderDetails = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_DETAILS_REQUEST
        })

        // const {
        //     // getting user info from userLogin in state
        //     // userLogin: { userInfo },
        // } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `${BACKEND_URL}/api/orders/${id}`,
            config
        )

        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const getUserOrderList = (queryParameters) => async(dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_LIST_MY_REQUEST
        })

        const {
            //getting user info from userLogin in state
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': userInfo.token
            }
        }

        const { data } = await axios.get(
            `${BACKEND_URL}/api/orders?${queryParameters}`,
            config
        )

        dispatch({
            type: ORDER_LIST_MY_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ORDER_LIST_MY_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const listOrders = (queryParameters) => async(dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_LIST_REQUEST
        })

        const {
            //getting user info from userLogin in state
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': userInfo.token
            }
        }

        const { data } = await axios.get(
            `${BACKEND_URL}/api/all-orders?${queryParameters}`,
            config
        )

        dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ORDER_LIST_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const updateOrder = (orderPayload) => async(dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_DELIVERED_REQUEST
        })

        const {
            //getting user info from userLogin in state
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': userInfo.token
            }
        }

        const { data } = await axios.put(
            `${BACKEND_URL}/api/orders/${orderPayload.id}/`,
            orderPayload,
            config
        )

        dispatch({
            type: ORDER_DELIVERED_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ORDER_DELIVERED_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}