import React, { useEffect } from 'react'
import { Row, Col, ListGroup, Image, Card, Button } from 'react-bootstrap'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { getOrderDetails, updateOrder } from '../../store/actions/orderActions'
import { ORDER_DELIVERED_RESET } from '../../store/constants/orderConstants'

function OrderScreen() {

  const { id } = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const orderDetails = useSelector(state => state.orderDetails)
  const { order, error, loading } = orderDetails

  const shippingInfo = useSelector(state => state.shippingInfo)
  const { shippingAddress } = shippingInfo

  const orderUpdate = useSelector(state => state.orderUpdate)
  const { loading: loadingUpdate, error: errorUpdate, success } = orderUpdate

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin
  // acc acumulator
  if (!loading && !error) {
    order.itemsPrice = order.order_items.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2)
  }

  useEffect(() => {
    // if (!userInfo) {
    //   navigate("/login")
    // }
    if (!order || order.id !== Number(id) || success) {
      dispatch({ type: ORDER_DELIVERED_RESET })

      dispatch(getOrderDetails(id))
    }
  }, [order, id, dispatch, success, navigate, userInfo]);

  const deliverHandler = () => {
    order.is_delivered = true
    dispatch(updateOrder(order))
  }

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error} </Message>
  ) : (
    <div>
      <h1> Your Order ID: {order.id}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Shipping</h2>

              <p>
                <strong>
                  Names: {order.name}, {order.phone_number}
                </strong>
              </p>

              <div>
                <p>
                  Your documents will be sent to <i>{order.email_address}</i> as soon as you complete your payment.
                </p>
                {' '}
              </div>

              {order.is_delivered ? (
                <Message variant="success">Delivered on {order.delivered_at ? order.delivered_at.substring(0, 10) : order.delivered_at} </Message>
              ) : <Message variant="warning">Awaiting Delivery</Message>}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>

              <p>
                <strong>Method: </strong>
                {order.payment_method}
              </p>
              {order.is_paid ? (
                <div>
                  <Message variant="success">Paid on {order.paid_at.substring(0, 10)} </Message>
                  { order.mpesa_code && <Message variant="info">Mpesa Code {order.mpesa_code} </Message>}
                </div>
              ) : <Message variant="warning">Pending Payment</Message>}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {order.order_items.length === 0 ? <Message variant="warning">
                You do not have an order
              </Message> : (
                <ListGroup variant='flush'>
                  {order.order_items.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image src={item.image} alt={item.name} fluid rounded />
                        </Col>

                        <Col>
                          <Link to={`/documents/${item.document_id}`}>{item.name}</Link>
                        </Col>

                        <Col md={4}>
                          {item.qty}x{item.price} = KES{(item.qty * item.price).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4} className='py-2'>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Items:</Col>
                  <Col>KES{order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Shipping:</Col>
                  <Col>KES{order.shipping_price}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Tax:</Col>
                  <Col>KES{order.tax_price}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Total:</Col>
                  <Col>KES{order.total_price}</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>

            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
            {userInfo && userInfo.is_admin && order.is_paid && !order.is_delivered && (
              <ListGroup.Item>
                <div className="d-grid gap-2">
                  <Button
                    type='button'
                    className='btn-block'
                    onClick={deliverHandler}>
                    Mark As Delivered
                  </Button>
                </div>
              </ListGroup.Item>
            )}
          </Card>
        </Col>
      </Row>

    </div>
  )
}

export default OrderScreen
