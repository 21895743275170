import { useEffect } from 'react'
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
} from 'react-bootstrap'
import Marquee from "react-fast-marquee";
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { NavDropdownMenu } from "react-bootstrap-submenu";

import { logout } from '../store/actions/userActions'
import SearchBox from './SearchBox'
import { listCategories } from '../store/actions/categoryActions'

function Header() {

  // dispatch
  const dispatch = useDispatch()

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const categoryList = useSelector(state => state.categoryList)
  const { categories } = categoryList

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(listCategories())
    }
  }, [dispatch, categories])

  //logout handler
  const logoutHander = () => {
    dispatch(logout())
  }

  return (
    <header>
      <div className='text-center p-2 text-white' style={{ backgroundColor: 'rgb(110, 63, 74)' }}>
        <Marquee>
          <span className='mr-4'><i className="fas fa-phone"></i> <span className='px-2'>Call us! 0718312587{" "} </span></span>
          {" "}
          <span className='ml-4'><i className="fas fa-envelope mr-2"></i> <span className='px-2'>Email us on! kenyastudyrepo@gmail.com{" "} </span></span>
          {" "}
          <span className='ml-4'><i className="fas fa-wallet ml-2"></i><span className='px-2'>Till Number: 6066300{" "}</span></span>
        </Marquee>
      </div>

      <Navbar bg="dark" variant='dark' expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>StudyRepo</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">

              <NavDropdownMenu title="Categories" id="collasible-nav-dropdown">
                {categories.map(category => (
                  <NavDropdown.Item key={category.id} href={`/category/${category.id}/documents`}>{category.name}</NavDropdown.Item>
                ))}
              </NavDropdownMenu>

              {userInfo ? (
                <NavDropdown title="Account" id='username'>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHander}>Logout</NavDropdown.Item>

                </NavDropdown>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link><i className='fas fa-user'></i> {" "} Login</Nav.Link>
                </LinkContainer>
              )}

              {userInfo && userInfo.is_admin && (
                <NavDropdown title="Admin" id='adminmenu'>
                  <LinkContainer to='/admin/users'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/categories'>
                    <NavDropdown.Item>Categories</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/documents'>
                    <NavDropdown.Item>Documents</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/orders'>
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to={"/admin/payments"}>
                    <NavDropdown.Item>Payments</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to={"/admin/mails"}>
                    <NavDropdown.Item>Mails</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
            <SearchBox />
            <LinkContainer to='/cart' className='text-white'>
                <Nav.Link><i className='fas fa-shopping-cart'></i>{" "} Cart</Nav.Link>
              </LinkContainer>
          </Navbar.Collapse>

        </Container>
      </Navbar>
    </header>
  )
}

export default Header
