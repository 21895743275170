import React from 'react'
import { Badge } from 'react-bootstrap'

function PillBadge({variant, children}) {
  return (
    <Badge pill bg={variant}>
        {children}
      </Badge>
  )
}

export default PillBadge
