import {
    MAIL_LIST_REQUEST,
    MAIL_LIST_SUCCESS,
    MAIL_LIST_FAIL,
    EMAIL_DETAILS_REQUEST,
    EMAIL_DETAILS_SUCCESS,
    EMAIL_DETAILS_FAIL,
    MAIL_CREATE_REQUEST,
    MAIL_CREATE_SUCCESS,
    MAIL_CREATE_FAIL,
    MAIL_CREATE_RESET,
    EMAIL_DELETE_REQUEST,
    EMAIL_DELETE_SUCCESS,
    EMAIL_DELETE_FAIL,
    MAIL_RECIPIENT_TOP_REQUEST,
    MAIL_RECIPIENT_TOP_SUCCESS,
    MAIL_RECIPIENT_TOP_FAIL,
} from "../constants/mailConstants"

// load data, error -> then manipulat the state and updates the store
export const mailListReducer = (state = { emails: [] }, action) => {
    switch (action.type) {
        case MAIL_LIST_REQUEST:
            return { loading: true, ...state } // documents:[]

        case MAIL_LIST_SUCCESS:
            return {
                loading: false,
                emails: action.payload.mail_draft_list,
                pagination: action.payload.pagination,
                page: action.payload.pagination.page,
                pages: action.payload.pagination.num_pages
            }

        case MAIL_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


// load data, error -> then manipulat the state and updates the store
export const mailDetailsReducer = (state = { email: { recipients: [] } }, action) => {
    switch (action.type) {
        case EMAIL_DETAILS_REQUEST:
            return { loading: true, ...state }

        case EMAIL_DETAILS_SUCCESS:
            return { loading: false, email: action.payload }

        case EMAIL_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

// load data, error -> then manipulat the state and updates the store
export const mailDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case EMAIL_DELETE_REQUEST:
            return { loading: true }

        case EMAIL_DELETE_SUCCESS:
            return { loading: false, success: true }

        case EMAIL_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

// load data, error -> then manipulat the state and updates the store
export const mailCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case MAIL_CREATE_REQUEST:
            return { loading: true }

        case MAIL_CREATE_SUCCESS:
            return { loading: false, success: true, email: action.payload }

        case MAIL_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case MAIL_CREATE_RESET:
            return {}

        default:
            return state
    }
}

// load data, error -> then manipulat the state and updates the store
// export const documentReviewCreateReducer = (state = {}, action) => {
//     switch (action.type) {
//         case DOCUMENT_CREATE_REVIEW_REQUEST:
//             return { loading: true }

//         case DOCUMENT_CREATE_REVIEW_SUCCESS:
//             return { loading: false, success: true }

//         case DOCUMENT_CREATE_REVIEW_FAIL:
//             return { loading: false, error: action.payload }

//         case DOCUMENT_CREATE_REVIEW_RESET:
//             return {}

//         default:
//             return state
//     }
// }

// load data, error -> then manipulat the state and updates the store
export const mailRecipientsListReducer = (state = { recipients: [] }, action) => {
    switch (action.type) {
        case MAIL_RECIPIENT_TOP_REQUEST:
            return { loading: true, ...state   }

        case MAIL_RECIPIENT_TOP_SUCCESS:
            return { 
                loading: false, 
                recipients: action.payload.draft_recipients,
                pagination: action.payload.pagination,
                page: action.payload.pagination.page,
                pages: action.payload.pagination.num_pages
            }

        case MAIL_RECIPIENT_TOP_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

// export const documentUpdateReducer = (state = {}, action) => {

//     switch(action.type) {

//         case DOCUMENT_UPDATE_REQUEST:
//             return {
//                 loading: true,
//             }
//         case DOCUMENT_UPDATE_SUCCESS:
//             return {
//                 loading: false,
//                 document: action.payload,
//                 success: true,
//             }

//         case DOCUMENT_UPDATE_FAIL:
//             return {
//                 loading: false,
//                 error: action.payload,
//             }
//         case DOCUMENT_UPDATE_RESET:
//             return {}
            
//         default:
//             return state

//     }
// }