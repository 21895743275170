import axios from "axios"
import { BACKEND_URL } from "../../utils/utils"
import { 
    PAYMENT_LIST_FAIL, 
    PAYMENT_LIST_REQUEST, 
    PAYMENT_LIST_SUCCESS,
} from "../constants/paymentConstants";

export const listPayments = (queryParameters) => async (dispatch, getState) => {
    
    try {

        dispatch({
            type: PAYMENT_LIST_REQUEST,
        })

        const  {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': userInfo.token
            }
        }

        const { data } = await axios.get(`${BACKEND_URL}/api/payment_requests?${queryParameters}`, config)
        
        dispatch({
            type: PAYMENT_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PAYMENT_LIST_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}
