import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Row, Col, ListGroup, Image, Button, Card, Form } from 'react-bootstrap'
import Message from '../../components/Message'
import { addToCart, removeFromCart } from '../../store/actions/cartActions'

function CartScreen() {

    const { id } = useParams()
    const navigate = useNavigate()
    const [queryParameters] = useSearchParams()
    const qty = queryParameters.get("qty") ? Number(queryParameters.get("qty").split("=")) : 1

    // dispatch only if document id
    const dispatch = useDispatch()

    const cart = useSelector(state => state.cart)

    const { cartItems } = cart

    // fire the action
    useEffect(() => {
        if (id) {
            dispatch(addToCart(id, qty))
        }
    }, [dispatch, id, qty])

    const removeFromCartHandler = (id) => {
        dispatch(removeFromCart(id))
    }

    const checkoutHandler = () => {
        // navigate("/login?redirect=shipping")
        navigate("/shipping")
    }
  return (
    <Row>
      <Col md={8}>
        <h1> Shopping Cart</h1>
        {cartItems.length === 0 ? (
            <Message variant='info'>
                Your cart is empty. <Link to='/'>Go back</Link>
            </Message>
        ): (
            <ListGroup  variant='flush'>
                {cartItems.map(item => (
                    <ListGroup.Item key={item.document_id}>
                        <Row>
                            <Col md={2}>
                                <Image src = {item.image} alt={item.name} fluid rounded />
                            </Col>
                            <Col md={3}>
                                <Link to={`/documents/${item.document_id}`}>{item.name}</Link>
                            </Col>
                            <Col md={2}>
                                Ksh {item.price}
                            </Col>
                            <Col md={3}>
                                <Form.Control
                                    as="select"
                                    value={item.qty}
                                    onChange={(e) => dispatch(addToCart(item.document_id, Number(e.target.value)))}
                                >
                                    {
                                        [...Array(item.count_in_stock).keys()].map((x)=>  (
                                            <option key={x + 1} value={ x + 1}>
                                                {x + 1}
                                            </option>
                                        ))
                                    }
                                </Form.Control>
                            </Col>
                            <Col md={1}>
                                <Button
                                    type="button"
                                    variant='light'
                                    onClick={() => removeFromCartHandler(item.document_id)}>
                                        <i className='fas fa-trash'></i>

                                </Button>
                            </Col>
                        </Row>
                    </ListGroup.Item>
            ))}
            </ListGroup>
        )}
      </Col>

      <Col md={4}>
        <Card>
            <ListGroup variant='flush'>
                <ListGroup.Item>
                    <h2> Subtotal ({ cartItems.reduce((acc, item)=> acc + item.qty, 0 ) }) items </h2>
                    Ksh { cartItems.reduce((acc, item)=> acc + item.qty * item.price, 0 ).toFixed(2) }
                </ListGroup.Item>

                <ListGroup.Item>

                <div className="d-grid gap-2">
                    <Button 
                        variant="primary" 
                        className='btn-block'
                        disabled={cartItems.length === 0}
                        onClick={checkoutHandler}>
                        Proceed to checkout
                    </Button>
                </div>
                </ListGroup.Item>

                <ListGroup.Item>
                    <div className="d-grid gap-2">
                        <Button 
                            variant="warning" 
                            className='btn-block'
                            href='/'>
                            Continue Shopping
                        </Button>
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </Card>
      </Col>
    </Row>
  )
}

export default CartScreen
