import React from 'react'
import { 
  Container, 
  Row, 
  Col,
  Button,
  Form
} from 'react-bootstrap'

function Footer() {
  return (
      <footer className='text-center bg-dark text-white'>
        <Container className='p-4'>
          <section className='mb-4'>
            <Button color="light"  className='m-1' href='https://web.facebook.com/profile.php?id=100088315796475' role='button' target='__blank'>
              <i className='fa-brands fa-facebook'></i>{" "}
            </Button>
  
            <Button  color="light"  className='m-1' href='https://twitter.com/k_studyrepo' role='button' target='__blank'>
            <i className='fa-brands fa-twitter'></i>{" "}
            </Button>
  
            <Button  color="light"  className='m-1' href='#!' role='button' target='__blank'>
            <i className='fa-brands fa-google'></i>{" "}
            </Button>
  
            <Button  color="light"  className='m-1' href='https://www.instagram.com/study.repo_kenya/' role='button' target='__blank'>
            <i className='fa-brands fa-instagram'></i>{" "}
            </Button>
  
            <Button  color="light"  className='m-1' href='#!' role='button' target='__blank'>
            <i className='fa-brands fa-linkedin'></i>{" "}
            </Button>
  
            <Button  color="light"  className='m-1' href='https://wa.me/0718312587' role='button' target='__blank'>
            <i className="fa-brands fa-whatsapp"></i>{" "}
            </Button>
          </section>
  
          <section className=''>
            <form action=''>
              <Row className='d-flex justify-content-center'>
                <Col size="auto">
                  <p className='pt-2'>
                    <strong>Sign up for our newsletter</strong>
                  </p>
                </Col>
                <Col md='5'>
                <Form.Control type="email" placeholder="Your email address" />
                <Form.Text id="passwordHelpBlock" muted>
                  We do not share your email address with any third parties.
                </Form.Text>
                </Col>
  
                <Col size="auto">
                  <Button  color='light' type='submit' className='mb-4'>
                    Subscribe
                  </Button>
                </Col>
              </Row>
            </form>
          </section>
  
          <section className='mb-4'>
            <p className='m-2 p-2'>
            We are a team of educators and professionals dedicated to providing high-quality, standard and 
            affordable education resources to help teachers, parents and students to succeed. Our materials are designed to be comprehensive,
             engaging, and easy to understand, covering a wide range of publishers. We offer a variety of content, including schemes of work, 
             lesson plans, records of work, topical questions, assessments and notes, so you can choose the best option for your needs. 
             Our team is committed to providing excellent customer service and supporting our customers every step of the way. 
            </p>

            <p>
            Thank you for choosing us as your partner in education.
            </p>

          </section>
  
          <section className=''>
            <Row>
              <Col lg='3' md='6' className='mb-4 mb-md-0'>
                <h5 className='text-uppercase'>Customer Service</h5>
  
                <ul className='list-unstyled mb-0'>
                  <li>
                    <a href='#!' className='text-white'>
                      Frequently Asked Questions
                    </a>
                  </li>

                  <li>
                    <a href='/login' className='text-white'>
                      Account
                    </a>
                  </li>
                </ul>
              </Col>
  
              <Col lg='3' md='6' className='mb-4 mb-md-0'>
                <h5 className='text-uppercase'>About us</h5>
  
                <ul className='list-unstyled mb-0'>
                  <li>
                    <a href='#!' className='text-white'>
                      Our Story
                    </a>
                  </li>
                  <li>
                    <a href='#!' className='text-white'>
                      Careers
                    </a>
                  </li>
                  <li>
                    <a href='#!' className='text-white'>
                      Jobs Portal
                    </a>
                  </li>
                </ul>
              </Col>
  
              <Col lg='3' md='6' className='mb-4 mb-md-0'>
                <h5 className='text-uppercase'>Shop with us</h5>  
                <ul className='list-unstyled mb-0'>

                <li>
                    <a href='#!' className='text-white'>
                      Shippings
                    </a>
                    <a href='#!' className='text-white'>
                      Terms and Conditions
                    </a>
                    <a href='#!' className='text-white'>
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </Col>
  
              <Col lg='3' md='6' className='mb-4 mb-md-0'>
                <h5 className='text-uppercase'>Phone</h5>
  
                <ul className='list-unstyled mb-0'>
                  <li>
                  <i className="fa-duotone fa-phone"></i> 0718312587
                  </li>

                  <li>
                  <span>
                    <i className="fas fa-envelope mr-2"></i>
                  </span>
                  {" "}
                  <a href='mailto:kenyastudyrepo@gmail.com'>kenyastudyrepo@gmail.com</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </section>
        </Container>
  
        <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          StudyRepo Kenya © 2023 Copyright. Designed by {" "}
          <a className='text-white ml-1' href='https://larntechconsultants.com/'>
            LarnTechConsultants
          </a>
        </div>
      </footer>
  
  )
}

export default Footer
