import { Container } from 'react-bootstrap'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Header from './components/Header'
import Footer from './components/Footer'

import HomeScreen from './screens/user/HomeScreen';
import DocumentScreen from './screens/user/DocumentScreen';
import CartScreen from './screens/user/CartScreen';
import ShippingScreen from './screens/user/ShippingScreen';
import PaymentScreen from './screens/user/PaymentScreen';
import PlaceOrderScreen from './screens/user/PlaceOrderScreen';
import OrderScreen from './screens/user/OrderScreen';
import LoginScreen from './screens/user/LoginScreen';
import RegisterScreen from './screens/user/RegisterScreen';
import ProfileScreen from './screens/user/ProfileScreen';
import UserListScreen from './screens/admin/UserListScreen';
import EditUserScreen from './screens/admin/EditUserScreen';
import DocumentListScreen from './screens/admin/DocumentListScreen';
import CategoryListScreen from './screens/admin/CategoryListScreen';
import EditDocumentScreen from './screens/admin/EditDocumentScreen';
import CreateCategoryScreen from './screens/admin/CreateCategoryScreen';
import EditCategoryScreen from './screens/admin/EditCategoryScreen';
import CreateDocument from './screens/admin/CreateDocument';
import CreateMailDraft from './screens/admin/CreateMailDraft';
import OrderListScreen from './screens/admin/OrderListScreen';
import DocumentsFilterScreen from './screens/user/DocumentsFilterScreen';
import Page404 from './screens/pages/Page404';
import PaymentListScreen from './screens/admin/PaymentListScreen';
import MailsListScreen from './screens/admin/MailsListScreen';

 
function App() {
  // ? in route urls make the parameter it comes after optional
  return (
    <Router>
      <Header />
      <main> 
        <Container className='py-3'>
        <Routes>
          {/* User routes */}
          <Route path="/" element={<HomeScreen />} exact />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/documents/:id" element={<DocumentScreen />} />
          <Route path="/documents/filter" element={<DocumentsFilterScreen />} />
          <Route path="/category/:id/documents" element={<DocumentsFilterScreen />} />
          <Route path="/cart" element={<CartScreen />} /> 
          <Route path="/cart/:id" element={<CartScreen />} /> 
          <Route path="/shipping" element={<ShippingScreen />} /> 
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/placeorder" element={<PlaceOrderScreen />} />
          <Route path="/order/:id" element={<OrderScreen />} />
          {/* Admin routes */}
          <Route path="/admin/users" element={<UserListScreen />} />
          <Route path="/admin/users/:id/edit" element={<EditUserScreen />} />
          <Route path="/admin/documents" element={<DocumentListScreen />} />
          <Route path="/admin/documents/create" element={<CreateDocument />} />
          <Route path="/admin/documents/:id/edit" element={<EditDocumentScreen />} />
          <Route path="/admin/categories" element={<CategoryListScreen />} />
          <Route path="/admin/categories/create" element={<CreateCategoryScreen />} />
          <Route path="/admin/categories/:id/edit" element={<EditCategoryScreen />} />
          <Route path="/admin/orders" element={<OrderListScreen />} />
          <Route path="/admin/payments" element={<PaymentListScreen />} />
          <Route path="/admin/mails" element={<MailsListScreen />} />
          <Route path="/admin/mails/create" element={<CreateMailDraft />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        </Container>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
