import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import FormContainer from '../../components/FormContainer'
import { saveShippingAddress } from '../../store/actions/cartActions'
import CheckoutSteps from '../../components/CheckoutSteps'
import FormContainer from '../../components/FormContainer'

function ShippingScreen() {

    const shippingInfo = useSelector(state => state.shippingInfo)
    const { shippingAddress } = shippingInfo

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [emailAddress, setEmailAddress] = useState(shippingAddress ? shippingAddress.emailAddress : '')
    const [name, setName] = useState(shippingAddress ? shippingAddress.name : '')
    const [phoneNumber, setPhoneNumber] = useState(shippingAddress ? shippingAddress.phoneNumber : '')
    //const [country, setCountry] = useState(shippingAddress ? shippingAddress.country : '')

    const submitHandler = (e) => {
        e.preventDefault()

        dispatch(saveShippingAddress({
            emailAddress,
            name,
            phoneNumber,
        }))

        navigate("/payment")
    }
    return (
        <FormContainer>
            <CheckoutSteps step1 />
            <hr />
            <h1>Client Details</h1>
            <Form onSubmit={submitHandler}>
                <Form.Group controlId="address">

                    <Form.Group controlId="city">
                        <Form.Label>Your Name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter Your Name"
                            value={name ? name : ''}
                            onChange={(e) => setName(e.target.value)}>
                        </Form.Control>
                    </Form.Group>

                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Enter Email Address"
                        value={emailAddress ? emailAddress : ''}
                        onChange={(e) => setEmailAddress(e.target.value)}>

                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="postalCode">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Enter Phone Number"
                        value={phoneNumber ? phoneNumber : ''}
                        onChange={(e) => setPhoneNumber(e.target.value)}>
                    </Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary' className='my-3'>
                    Continue
                </Button>
            </Form>
        </FormContainer>
    )
}

export default ShippingScreen
