import React, { useEffect, useCallback } from 'react'
import { Table, Button, Row, Col, Card } from 'react-bootstrap'
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { listDocuments, deleteDocument } from '../../store/actions/documentActions'
import { truncate } from '../../utils/utils'
import Paginate from '../../components/Paginate'
import BootstrapTable from 'react-bootstrap-table-next';
import SearchBox from '../../components/SearchBox'



function DocumentListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [queryParameters, setSearchParams] = useSearchParams()
  //const term = queryParameters.get("term")

  // getting state
  const documentList = useSelector(state => state.documentList)
  const { loading, error, documents, page, pages } = documentList

  const documentDelete = useSelector(state => state.documentDelete)
  const { loading: loadingDelete, error: errorDelete, success: successDelete } = documentDelete

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin


  useEffect(() => {
    if (userInfo && userInfo.is_admin) {
      dispatch(listDocuments(queryParameters))
    } else {
      navigate('/login')
    }
  }, [dispatch, navigate, userInfo, successDelete, queryParameters])

  //TODO: Add to utils package and export the function
  // TODO: Make it abstract on the param value
  const handleChangePage = useCallback((page) => {

    let params = Object.fromEntries([...queryParameters])
    if (params.hasOwnProperty("page")) {
      params.page = page
    } else {
      params["page"] = page
    }

    setSearchParams(createSearchParams(params))
  }, [setSearchParams, queryParameters])


  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      //DELETE DOCUMENTS
      dispatch(deleteDocument(id))
    }
  }

  return (
    <div>
      <Card className='shadow mb-4'>
        <Card.Header>
          <Card.Title>
            <Row>
              <Col md="8" className='mt-2'>
                  Documents
              </Col>
              <Col md="4">
                <SearchBox />
              </Col>

            </Row>
          </Card.Title>
        </Card.Header>
        <Card.Body className='p-4'>
          {loadingDelete && <Loader />}

          {errorDelete && <Message variant='danger'>{errorDelete} </Message>}

          {loading
            ? (<Loader />)
            : error
              ? (<Message variant='danger'>{error}</Message>)
              : (
                <div>
                  <Table striped responsive bordered hover className='table-sm'>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Grade</th>
                        <th>Level</th>
                        <th>Type</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {documents.map(document => (
                        <tr key={document.id}>
                          <td>{document.id}</td>
                          <td>{truncate(document.name, 100)}</td>
                          <td>KES {document.price}</td>
                          <td>{document.grade}</td>
                          <td>{document.document_level}</td>
                          <td>{document.document_type}</td>
                          <td>
                            <LinkContainer to={`/admin/documents/${document.id}/edit`}>
                              <Button className='btn-sm' variant='light'>
                                <i className='fas fa-edit'></i>
                              </Button>
                            </LinkContainer>

                            <Button className='btn-sm' variant='danger' onClick={() => deleteHandler(document.id)}>
                              <i className='fas fa-trash ml-4'></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {pages > 1 && (
                    <Paginate
                      total={pages}
                      current={page}
                      onChangePage={handleChangePage} />
                  )}
                </div>
              )}

          <Row>
            <Col className='justify-content-end text-right'>

              <LinkContainer to={`/admin/documents/create`}>
                <Button className='my-3'>
                  <i className='fas fa-plus'></i> Create Document
                </Button>
              </LinkContainer>

            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default DocumentListScreen
