import axios from "axios";
import { BACKEND_URL } from "../../utils/utils";
import {
    DOCUMENT_CATEGORY_LIST_REQUEST,
    DOCUMENT_CATEGORY_LIST_SUCCESS,
    DOCUMENT_CATEGORY_LIST_FAIL,

    DOCUMENT_CATEGORY_DELETE_REQUEST,
    DOCUMENT_CATEGORY_DELETE_SUCCESS,
    DOCUMENT_CATEGORY_DELETE_FAIL,

    DOCUMENT_CATEGORY_DETAILS_REQUEST,
    DOCUMENT_CATEGORY_DETAILS_SUCCESS,
    DOCUMENT_CATEGORY_DETAILS_FAIL,

    DOCUMENT_CATEGORY_CREATE_REQUEST,
    DOCUMENT_CATEGORY_CREATE_SUCCESS,
    DOCUMENT_CATEGORY_CREATE_FAIL,
} from "../constants/categoryConstants";

export const listCategories = () => async (dispatch, getState) => {
    try {

        dispatch({ type: DOCUMENT_CATEGORY_LIST_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        const { data } = await axios.get(
            `${BACKEND_URL}/api/document_types`,
            config,
        )

        dispatch({
            type: DOCUMENT_CATEGORY_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: DOCUMENT_CATEGORY_LIST_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const deleteCategory = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DOCUMENT_CATEGORY_DELETE_REQUEST
        })

        const {
            //getting user info from userLogin in state
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': userInfo.token
            }
        }

        await axios.delete(
            `${BACKEND_URL}/api/document_types/${id}`,
            config
        )

        dispatch({
            type: DOCUMENT_CATEGORY_DELETE_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: DOCUMENT_CATEGORY_DELETE_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const getCategoryDetails = (id) => async (dispatch, getState) => {

    try {
        dispatch({ type: DOCUMENT_CATEGORY_DETAILS_REQUEST })

        const { data } = await axios.get(`${BACKEND_URL}/api/document_types/${id}`)

        dispatch({
            type: DOCUMENT_CATEGORY_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DOCUMENT_CATEGORY_DETAILS_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const createCategory = (payload) => async(dispatch, getState) => {
    
    try {
        dispatch({
            type: DOCUMENT_CATEGORY_CREATE_REQUEST
        })

        const {
            //getting user info from userLogin in state
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': userInfo.token
            }
        }

        const { data } = await axios.post(
            `${BACKEND_URL}/api/document_types`,
            payload,
            config
        )

        dispatch({
            type: DOCUMENT_CATEGORY_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: DOCUMENT_CATEGORY_CREATE_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}