import React , {useState, useEffect} from 'react'
import { Carousel, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Message from './Message'
import Loader from './Loader'
import { listTopDocuments } from '../store/actions/documentActions'

function DocumentCarousel() {
    const dispatch = useDispatch()

    const documentTopRated = useSelector(state => state.documentTopRated)
    const { error, loading, documents } = documentTopRated

    useEffect(() => {
        dispatch(listTopDocuments())
    }, [dispatch]);

  return (loading ? <Loader />
    :error
    ?<Message variant='danger'>{error}</Message>
    : (
        <Carousel
            pause='hover'
            className='bg-dark'>
                {documents.map(document => (
                    <Carousel.Item key={document.id}>
                        <Link to={`/documents/${document.id}`}>
                            <Image src = {document.cover_image} alt={document.name} fluid/>
                            <Carousel.Caption className='carousel.caption mb-4'>
                               <div>
                                    <h4 className='mb-6'>{document.name} (KES {document.price})</h4>
                               </div>
                            </Carousel.Caption>
                        </Link>
                    </Carousel.Item>
                ))}
            
        </Carousel>
    )
  )
}

export default DocumentCarousel
