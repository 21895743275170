import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

function DocumentSearchSelect({ handleChangeTerm, handleChangeSubject }) {

    return (
        <div>

            <Form inline="true">
                <Row>
                    <Col md={6} className="mr-0">
                        <Form.Select onChange={handleChangeSubject} aria-label="Select Subject">
                            <option value="">Select Subject</option>
                            <option value="mathematics">Mathematics</option>
                            <option value="english">English</option>
                            <option value="kiswahili">Kiswahili</option>
                            <option value="science and technology">Science and Technology</option>
                            <option value="agriculture">Agriculture</option>
                            <option value="movement">Movement</option>
                            <option value="Physical and health education">Physical and health education</option>
                            <option value="Home Science">Home Science</option>
                            <option value="Social Studies">Social Studies</option>
                            <option value="Art and Craft">Art and Craft</option>
                            <option value="Visual Arts">Visual Arts</option>
                            <option value="Creative Arts">Creative Arts</option>
                            <option value="Creative Arts and Sports">Creative Arts and Sports</option>
                            <option value="Agriculture and Nutrition">Agriculture and Nutrition</option>
                            <option value="Performing Arts">Performing Arts</option>
                            <option value="Music">Music</option>
                            <option value="IRE">IRE</option>
                            <option value="Environmental">Environmental</option>
                            <option value="Literacy">Literacy</option>
                            <option value="Hygiene and nutrition">Hygiene and nutrition</option>
                            <option value="Life Skills">Life Skills</option>
                            <option value="Business Studies">Business Studies</option>
                            <option value="CRE">CRE</option>
                            <option value="Pre-technical Studies">Pre-technical Studies</option>
                            <option value="Integrated Science">Integrated Science</option>
                            <option value="Health Education">Health Education</option>
                            <option value="Physical Education and Sports">Physical Education and Sports</option>
                            <option value="geography">Geography</option>
                            <option value="history">History</option>
                            <option value="arabic">Arabic</option>
                            <option value="french">French</option>
                            <option value="chemistry">Chemistry</option>
                            <option value="physics">Physics</option>
                            <option value="biology">Biology</option>
                            <option value="Indigenous Language"> Indigenous Language </option>
                        </Form.Select>

                    </Col>
                    <Col md={6} className="ml-0">
                        <Form.Select aria-label="Select Term" onChange={handleChangeTerm}>
                            <option value="">Select Term</option>
                            <option value="Term 1">Term 1</option>
                            <option value="Term 2">Term 2</option>
                            <option value="Term 3">Term 3</option>
                        </Form.Select>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default DocumentSearchSelect
