import axios from "axios";
import { BACKEND_URL } from "../../utils/utils";

import { 
    CART_ADD_ITEM, 
    CART_REMOVE_ITEM,

    CART_SAVE_SHIPPING_ADDRESS,

    CART_SAVE_PAYMENT_METHOD,
 } from '../constants/cartConstants'

// use state is used to access the state
// we will use it to uodate the localstorage by getting data from the broswer
export const addToCart = (id, qty) => async(dispatch, getState) => {
    const {data} = await axios.get(`${BACKEND_URL}/api/documents/${id}`)

    dispatch({
        type: CART_ADD_ITEM,
        payload: {
            document_id: data.id,
            name: data.name,
            image: data.cover_image,
            price: data.price,
            count_in_stock: data.count_in_stock,
            qty
        }
    })

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = (id) => (dispatch, getState) => {
    dispatch({
        type: CART_REMOVE_ITEM,
        payload: id,
    })

    // update the local storage
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}


export const saveShippingAddress = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_SHIPPING_ADDRESS,
        payload: data,
    })

    // update the local storage
    localStorage.setItem('shippingAddress', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_PAYMENT_METHOD,
        payload: data,
    })

    // update the local storage
    localStorage.setItem('paymentMethod', JSON.stringify(data))
}