// import { config } from "@fortawesome/fontawesome-svg-core"
import axios from "axios"
import { MAIL_URL, USERNAME, PASSWORD, ACCOUNT_ID} from "../../utils/utils"
import { 
    MAIL_LIST_REQUEST,
    MAIL_LIST_SUCCESS,
    MAIL_LIST_FAIL,
    EMAIL_DETAILS_REQUEST,
    EMAIL_DETAILS_SUCCESS,
    EMAIL_DETAILS_FAIL,
    MAIL_CREATE_REQUEST,
    MAIL_CREATE_SUCCESS,
    MAIL_CREATE_FAIL,
    MAIL_CREATE_RESET,
    EMAIL_DELETE_REQUEST,
    EMAIL_DELETE_SUCCESS,
    EMAIL_DELETE_FAIL,
    MAIL_RECIPIENT_TOP_REQUEST,
    MAIL_RECIPIENT_TOP_SUCCESS,
    MAIL_RECIPIENT_TOP_FAIL,
} from "../constants/mailConstants"
// dispatch is used to dispatch the actions in reducers

// dispatch is used to dispatch the actions in reducers
export const listMails = (queryParameters) => async (dispatch) => {
    
    try {
        dispatch({type: MAIL_LIST_REQUEST})

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },

            auth: {
                username: USERNAME,
                password: PASSWORD
            }
        }

        const { data } = await axios.get(
            `${MAIL_URL}/api/account/${ACCOUNT_ID}/api_mails?${queryParameters}`,
            config,
            )

        dispatch({
            type: MAIL_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: MAIL_LIST_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const getMailDetails = (id) => async (dispatch) => {
    try {
        dispatch({type: EMAIL_DETAILS_REQUEST})

        const { data } = await axios.get(`${MAIL_URL}/api/mail_draft/${id}`)

        dispatch({
            type: EMAIL_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: EMAIL_DETAILS_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const deleteMail = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: EMAIL_DELETE_REQUEST
        })

        const {
            //getting user info from userLogin in state
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': userInfo.token
            }
        }

        await axios.delete(
            `${MAIL_URL}/api/documents/${id}`,
            config
        )

        dispatch({
            type: EMAIL_DELETE_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: EMAIL_DELETE_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const createMail = (mailData) => async(dispatch, getState) => {
    try {
        dispatch({
            type: MAIL_CREATE_REQUEST
        })

        const {
            //getting user info from userLogin in state
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': userInfo.token
            },

            auth: {
                username: USERNAME,
                password: PASSWORD
            }
        }

        const { data } = await axios.post(
            `${MAIL_URL}/api/api_mails`,
            mailData,
            config
        )

        dispatch({
            type: MAIL_CREATE_SUCCESS,
            payload: data,
        })

        dispatch({type: MAIL_CREATE_RESET})

    } catch (error) {
        dispatch({
            type: MAIL_CREATE_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}

export const listMailRecipients = (draft_id, queryParameters) => async (dispatch) => {
    
    try {
        dispatch({type: MAIL_RECIPIENT_TOP_REQUEST})

        const { data } = await axios.get(`${MAIL_URL}/api/mail_drafts/${draft_id}/draft_recipients?${queryParameters}`)

        dispatch({
            type: MAIL_RECIPIENT_TOP_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: MAIL_RECIPIENT_TOP_FAIL,
            payload: error.response && error.response.data.error_message
                ? error.response.data.error_message
                : error.message
        })
    }
}
