import React from 'react'
import { Pagination } from 'react-bootstrap'

function Paginate({ total = 1, current = 1, onChangePage }) {


    let items = []
    let start, end;
    let ellipsis = false;

    if (current > 1) {
        items.push(<Pagination.Prev key="prev" onClick={() => onChangePage(current - 1)} />)
    }

    if (total <= 4) {
        start = 1;
        end = total;
    } else {
        if (current <= 2) {
            start = 1;
            end = 4;
        } else if (current >= total - 1) {
            start = total - 3;
            end = total;
        } else {
            start = current - 1;
            end = current + 1;
            ellipsis = true;
        }
    }

    for (let page = start; page <= end; page++) {
        items.push(
            <Pagination.Item
                key={page}
                date-page={page}
                active={page === current}
                onClick={() => onChangePage(page)}>
                {page}
            </Pagination.Item>
        )
    }
    if (ellipsis) {
        items.splice(2, 0, <Pagination.Ellipsis key="ellipsis" disabled />)
    }
    if (current < total) {
        items.push(
            <Pagination.Next key="next" onClick={() => onChangePage(current + 1)} />
        )
    }

    return (
        <Pagination>
            {items}
        </Pagination>
    )

}

export default Paginate
